import { createSlice } from '@reduxjs/toolkit';

import { State } from './type';
import {
  addUserEmployee,
  getDetailEmployee,
  getListEmployeeInDepartment,
  getRecordEmployee,
  getTitle,
  listAllProject,
  removeEmployee,
  searchEmployee,
  searchEmployeeResource,
  updateEmployee,
  uploadCVautoFill,
} from './actions';

const initialState: State = {
  list: {
    loading: false,
    result: [],
  },
  item: {
    loading: false,
    result: [],
  },
  read: {
    loading: false,
    result: undefined,
  },
  update: {
    loading: false,
    result: undefined,
  },
  remove: {
    loading: false,
    result: undefined,
  },
  resource: {
    loading: false,
    result: undefined,
  },
  listTitle: {
    loading: false,
    result: undefined,
  },
  record: {
    loading: false,
    result: undefined,
  },
  uploadCv: {
    loading: false,
    result: undefined,
  },
  listProjectName: {
    loading: false,
    result: undefined,
  },
};

export const slice = createSlice({
  name: 'employeeInRole',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getListEmployeeInDepartment.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(getListEmployeeInDepartment.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(getListEmployeeInDepartment.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });

    builder.addCase(addUserEmployee.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });

    builder.addCase(addUserEmployee.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });

    builder.addCase(addUserEmployee.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
    builder.addCase(getDetailEmployee.pending, (state) => {
      state.read.loading = true;
      state.read.result = initialState.read.result;
      state.read.error = {};
    });
    builder.addCase(getDetailEmployee.fulfilled, (state, action) => {
      state.read.loading = false;
      state.read.result = action.payload;
      state.read.error = {};
    });
    builder.addCase(getDetailEmployee.rejected, (state, action) => {
      state.read.loading = false;
      state.read.error = action.error;
      state.read.result = initialState.read.result;
    });
    builder.addCase(updateEmployee.pending, (state) => {
      state.update.loading = true;
      state.update.result = initialState.update.result;
      state.update.error = {};
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      state.update.loading = false;
      state.update.result = action.payload;
      state.update.error = {};
    });
    builder.addCase(updateEmployee.rejected, (state, action) => {
      state.update.loading = false;
      state.update.error = action.error;
      state.update.result = initialState.update.result;
    });
    builder.addCase(removeEmployee.pending, (state) => {
      state.remove.loading = true;
      state.remove.result = initialState.remove;
      state.remove.error = {};
    });
    builder.addCase(removeEmployee.fulfilled, (state, action) => {
      state.remove.loading = false;
      state.remove.result = action.payload;
      state.remove.error = {};
    });
    builder.addCase(removeEmployee.rejected, (state, action) => {
      state.remove.loading = false;
      state.remove.error = action.error;
      state.remove.result = initialState.remove.result;
    });

    builder.addCase(searchEmployee.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(searchEmployee.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(searchEmployee.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });
    builder.addCase(searchEmployeeResource.pending, (state) => {
      state.resource.loading = true;
      state.resource.result = initialState.resource.result;
      state.resource.error = {};
    });
    builder.addCase(searchEmployeeResource.fulfilled, (state, action) => {
      state.resource.loading = false;
      state.resource.result = action.payload;
      state.resource.error = {};
    });
    builder.addCase(searchEmployeeResource.rejected, (state, action) => {
      state.resource.loading = false;
      state.resource.error = action.error;
      state.resource.result = initialState.resource.result;
    });
    builder.addCase(getTitle.pending, (state) => {
      state.listTitle.loading = true;
      state.listTitle.result = initialState.listTitle.result;
      state.listTitle.error = {};
    });
    builder.addCase(getTitle.fulfilled, (state, action) => {
      state.listTitle.loading = false;
      state.listTitle.result = action.payload;
      state.listTitle.error = {};
    });
    builder.addCase(getTitle.rejected, (state, action) => {
      state.listTitle.loading = false;
      state.listTitle.error = action.error;
      state.listTitle.result = initialState.listTitle.result;
    });
    builder.addCase(getRecordEmployee.pending, (state) => {
      state.record.loading = true;
      state.record.result = initialState.record.result;
      state.record.error = {};
    });
    builder.addCase(getRecordEmployee.fulfilled, (state, action) => {
      state.record.loading = false;
      state.record.result = action.payload;
      state.record.error = {};
    });
    builder.addCase(getRecordEmployee.rejected, (state, action) => {
      state.record.loading = false;
      state.record.error = action.error;
      state.record.result = initialState.record.result;
    });

    builder.addCase(uploadCVautoFill.pending, (state) => {
      state.uploadCv.loading = true;
      state.uploadCv.result = initialState.uploadCv.result;
      state.uploadCv.error = {};
    });
    builder.addCase(uploadCVautoFill.fulfilled, (state, action) => {
      state.uploadCv.loading = false;
      state.uploadCv.result = action.payload;
      state.uploadCv.error = {};
    });
    builder.addCase(uploadCVautoFill.rejected, (state, action) => {
      state.uploadCv.loading = false;
      state.uploadCv.error = action.error;
      state.uploadCv.result = initialState.uploadCv.result;
    });

    builder.addCase(listAllProject.pending, (state) => {
      state.listProjectName.loading = true;
      state.listProjectName.result = initialState.listProjectName.result;
      state.listProjectName.error = {};
    });
    builder.addCase(listAllProject.fulfilled, (state, action) => {
      state.listProjectName.loading = false;
      state.listProjectName.result = action.payload;
      state.listProjectName.error = {};
    });
    builder.addCase(listAllProject.rejected, (state, action) => {
      state.listProjectName.loading = false;
      state.listProjectName.error = action.error;
      state.listProjectName.result = initialState.listProjectName.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  getListEmployeeInDepartment,
  addUserEmployee,
  getDetailEmployee,
  removeEmployee,
  updateEmployee,
  searchEmployee,
  searchEmployeeResource,
  getTitle,
  getRecordEmployee,
  uploadCVautoFill,
  listAllProject,
};

export const { reducer } = slice;
