import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActionParams } from '../types/common';
import api from '@app/api';
import { createAsyncAction } from '@app/services/thunkWrapper';

export const getDepartmentList = createAsyncThunk(
  'list/department_list',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/departments`;

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);

export const getDepartmentByAccount = createAsyncThunk(
  'list/department_account',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/project/dept_by_account`;

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);
