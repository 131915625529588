import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: any) => state.employeeinrole;

export const selector = {
  selectList: createSelector(selectSelf, (state) => state.list),
  selectItem: createSelector(selectSelf, (state) => state.item),
  selectRead: createSelector(selectSelf, (state) => state.read),
  selectUpdate: createSelector(selectSelf, (state) => state.update),
  selectRemove: createSelector(selectSelf, (state) => state.remove),
  selectResource: createSelector(selectSelf, (state) => state.resource),
  selectListTitle: createSelector(selectSelf, (state) => state.listTitle),
  selectRecordEmployee: createSelector(selectSelf, (state) => state.record),
  selectListCVUpload: createSelector(selectSelf, (state) => state.uploadCv),
  selectListProjectName: createSelector(selectSelf, (state) => state.listProjectName),
};
