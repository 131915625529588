import { Input, Modal, Select, TreeSelect } from 'antd';
import styled from 'styled-components';

export const CustomModal = styled(Modal)`
  .ant-modal-header {
    background-color: #f9f0ff;
  }
`;

export const WrapperGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
`;
export const CustomInput = styled(Input)`
  &.ant-form-item-control-input-content {
    border-radius: 4px !important;
  }
`;
export const CustomSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 4px;
  }
`;

export const CustomTreeSelect = styled(TreeSelect)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;
export const FormListStyle = styled('div')`
  display: flex;
  align-items: start;
  margin: 0 -4px;
  &:last-of-type {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
`;
export const FormItemStyle = styled('div')`
  padding: 0 4px;
  width: 100%;
  display: flex;
  &.layoutColumn {
    flex-direction: column;
  }
  .ant-form-item {
    width: 100%;
  }
`;

export const TableFormStyle = styled('div')`
  margin-bottom: 16px;
  .table-container {
    display: flex;
    flex-flow: column nowrap;
    background-color: white;
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    /* overflow-x: auto; */
  }

  .table-row-group {
    border-bottom: 1px solid #d9d9d9;
    &:hover {
      background-color: rgb(0, 0, 0, 0.02);
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    }
  }

  .table-row {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    /* &:not(.row-item--full), */
    &:not(.heading) {
      background-color: #f5f5f5;
    }
  }

  .heading {
    background-color: #9254de;
    color: #ffffff;
    font-weight: bold;
    .row-item {
      justify-content: center;
    }
  }

  .row-item {
    display: flex;
    flex: 1;
    font-size: 14px;
    padding: 8px 0;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 0.15s ease-in-out;
    padding: 10px 6px;
    border-right: 1px solid #d9d9d9;
    word-break: break-word;
    &--action {
      width: 70px;
      flex: none;
    }
    &:nth-child(3) {
      width: 120px;
      flex: none;
    }
    &:last-child {
      border-right: none;
    }
  }

  /* .row-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, .08);
  } */

  /* .row-sub-container {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
  }

  .row-sub-container .row-item {
    padding: 8px 0;
    border-bottom: 1px solid #D9D9D9;
  } */

  /* .table-row:last-child,
  .row-sub-container .row-item:last-child {
    border-bottom: 0;
  } */
`;
export const FormListWrapStyle = styled('div')`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-bottom: 24px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
`;
export const LoadingWrap = styled('div')`
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
