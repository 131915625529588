import { DashboardOutlined, UserOutlined } from '@ant-design/icons';
import { DashboardIcon, EmployeeIcon, ProjectIcon, ResourceIcon, SearchIcon } from '@app/assets/svg';
import React from 'react';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'sider.dashboard',
    key: 'dashboard',
    icon: <DashboardIcon />,
    url: '/dashboard',
  },
  {
    title: 'sider.candidates',
    key: 'candidates',
    icon: <UserOutlined />,
    url: '/candidates',
  },
  {
    title: 'sider.list-employee',
    key: 'list-employee',
    icon: <EmployeeIcon />,
    url: '/employee',
  },

  {
    title: 'sider.resource-allocation',
    key: 'resource-allocation',
    icon: <ResourceIcon />,
    url: '/project',
  },
  {
    title: 'sider.search-employee',
    key: 'search',
    icon: <SearchIcon />,
    url: '/search',
  },
];
