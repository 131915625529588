import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActionParams } from '../types/common';
import api from '@app/api';
import { createAsyncAction } from '@app/services/thunkWrapper';
import { buildUrl } from '@app/services/app';

export const getProjectByBul = createAsyncThunk(
  'list/project_by_bul',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/project/list`, payload.params);

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);
export const getProjectByBulSearch = createAsyncThunk(
  'list/project_by_bul_search',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/resource-allocation/project_by_bul`, payload.params);

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);
export const getProjectByName = createAsyncThunk(
  'list/project_by_name',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/project/${payload.params.project_code}/info`;

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);

export const addProjectByBul = createAsyncThunk(
  'add/add_project',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/project/insert`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;
    return data;
  }),
);

export const getEmployeeInProject = createAsyncThunk(
  'list/employee_in_project',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/resource-allocation/get_empl_in_project?project_code=${payload.params.project_name}&search_word=${payload.params.search_word}`;

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);

export const addEmployeeToProject = createAsyncThunk(
  'add/add_employee_to_project',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/resource-allocation/add_empl_to_project`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;
    return data;
  }),
);

export const delEmployeeToProject = createAsyncThunk(
  'del/del_employee_to_project',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/resource-allocation/delete_empl_in_project/${payload.params.employeeId}/${payload.params.projectId}`;

    const response = await api.caller.delete(endpoint);
    const data = response.data;
    return data;
  }),
);

export const updateEmployeeToProject = createAsyncThunk(
  'update/update_employee_to_project',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/resource-allocation/update_empl_in_project`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;
    return data;
  }),
);

export const detailEmployeeToProject = createAsyncThunk(
  'detail/detail_employee_to_project',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(
      `${process.env.REACT_APP_API_URL}/resource-allocation/detail_empl_in_project`,
      payload.params,
    );

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);
export const delResource = createAsyncThunk(
  'del/del_resource',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/resource-allocation/delete_project/${payload.params}`;

    const response = await api.caller.delete(endpoint);
    const data = response.data;
    return data;
  }),
);

export const updateProject = createAsyncThunk(
  'update/update_project',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/resource-allocation/update_project`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;
    return data;
  }),
);

export const getListAllProjectCode = createAsyncThunk(
  'list/project_code',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/project/get_codes`;

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);
