import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state: any) => state.upload;

export const selector = {
  selectProjectData: createSelector(selectSelf, (state) => state),
  selectListReview: createSelector(selectSelf, (state) => state.review),
  selectList: createSelector(selectSelf, (state) => state.list),
  selectListReviewJira: createSelector(selectSelf, (state) => state.reviewJira),
  selectDataJira: createSelector(selectSelf, (state) => state.dataJira),
  selectUpdateCV: createSelector(selectSelf, (state) => state.updateCV),
};
