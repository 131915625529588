import styled from 'styled-components';
import { BREAKPOINTS } from '@app/styles/themes/constants';
import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';

export const LayoutMaster = styled(BaseLayout)`
  height: 100vh;

  .ant-layout-sider-children {
    background-color: white;
    height: 100vh;
  }
`;

export const LayoutMain = styled(BaseLayout)`
  @media only screen and(min-width: ${BREAKPOINTS.md}px) {
    margin-left: 80px;
    background-color: #f0f0f0;
  }

  @media only screen and(min-width: ${BREAKPOINTS.xl}px) {
    margin-left: unset;
  }
`;
