export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_ADB2C_CLIENT_ID || '',
    authority: process.env.REACT_APP_ADB2C_AUTHORITY || '',
    knownAuthorities: [],
    redirectUri: `${window.location.origin}/`,
    postLogoutRedirectUri: process.env.REACT_APP_ADB2C_POST_LOGOUT_REDIRECT_URI || '',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

export const loginRequest = {
  scopes: ['api://candidate-authen-pro/candidate.scope'],
};

export const editProfile = {
  authority: process.env.REACT_APP_ADB2C_EDIT_PROFILE_AUTHORITY,
};

export const policyNames = {
  signUpSignIn: process.env.REACT_APP_ADB2C_SIGNUP_SIGNIN_POLICY,
};
