import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActionParams } from '../types/common';
import api from '@app/api';
import { createAsyncAction } from '@app/services/thunkWrapper';

export const getListEmployee = createAsyncThunk(
  'list/get_list',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/search_subset`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const getOneEmployee = createAsyncThunk(
  'one/detail_list',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/employees/${payload.params}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  }),
);

export const getCVHistory = createAsyncThunk(
  'list/get_cv_history',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/upload_cv/${payload.params}`;

    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  }),
);

export const getProjectByEmployee = createAsyncThunk(
  'list/get_project_by_employee',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/account_resource_allocation?account=${payload.params}`;

    const response = await api.caller.get(endpoint);
    const data = response.data?.outputs;
    return data;
  }),
);

export const deleteCV = createAsyncThunk(
  'one/delete_cv',
  createAsyncAction(async (payload: ActionParams) => {
    const { account, skill, seniority, job_title, _id } = payload.params as any;
    const endpoint = `${process.env.REACT_APP_API_URL}/upload_cv/${account}/${skill}/${seniority}/${job_title}/${_id}`;

    const response = await api.caller.delete(endpoint);
    const data = response.data;
    return data;
  }),
);

export const getAccountProfessionProject = createAsyncThunk(
  'list/account_profession_project',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/account_profession_project?account=${payload.params}`;

    const response = await api.caller.get(endpoint);
    const data = response.data?.outputs;
    return data;
  }),
);

export const getListNotes = createAsyncThunk(
  'list/list_cv',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/note/${payload.params?.employee_id}`;

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);

export const insertNote = createAsyncThunk(
  'note/insert_note',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/note/${payload.params?.employee_id}`;

    const response = await api.caller.post(endpoint, payload.params?.data);
    const data = response.data;
    return data;
  }),
);

export const updateNote = createAsyncThunk(
  'note/update_note',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/note/${payload.params?.employee_id}/${payload.params.employee_note_id}`;

    const response = await api.caller.put(endpoint, payload.params?.data);
    const data = response.data;
    return data;
  }),
);

export const deleteNote = createAsyncThunk(
  'note/delete_note',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/note/${payload.params?.employee_id}/${payload.params.employee_note_id}`;

    const response = await api.caller.delete(endpoint);
    const data = response.data;
    return data;
  }),
);
