import { createSelector } from '@reduxjs/toolkit';
import { State } from './type';

const selectSelf = (state: { resource: any }) => state.resource;

export const selector = {
  selectList: createSelector(selectSelf, (state) => state.list),
  selectListByName: createSelector(selectSelf, (state) => state.listName),
  selectItem: createSelector(selectSelf, (state) => state.item),
  selectRead: createSelector(selectSelf, (state) => state.read),
  selectUpdate: createSelector(selectSelf, (state) => state.update),
  selectRemove: createSelector(selectSelf, (state) => state.remove),
  selectListProject: createSelector(selectSelf, (state) => state.listProject),
  selectItemProject: createSelector(selectSelf, (state) => state.itemProject),
  selectRemoveProject: createSelector(selectSelf, (state) => state.removeProject),
  selectUpdateProject: createSelector(selectSelf, (state) => state.updateProject),
  selectDetailProject: createSelector(selectSelf, (state) => state.detailProject),
  selectDetailResource: createSelector(selectSelf, (state) => state.delete),
  selectProjectCode: createSelector(selectSelf, (state) => state.listProjectCode),
};
