import styled from 'styled-components';

export const BreakcumbWrap = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  margin-bottom: 8px;
  a {
    text-transform: capitalize;
    &:not(:hover) {
      line-height: 20px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  > div:last-child {
    a {
      color: #000000;
      pointer-events: none;
    }
  }
  > div:only-child a {
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const BreakcumbSlash = styled('span')`
  display: inline-block;
  width: 20px;
  height: 23px;
  text-align: center;
  vertical-align: middle;
  color: #8c8c8c;
`;
