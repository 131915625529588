import { Button } from 'antd';
import styled from 'styled-components';

export const CustomButtonImport = styled(Button)`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-weight: 500;
  background-color: white;
  color: #9254de;
  width: auto;
  margin-right: 10px;
`;
