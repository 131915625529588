// searchInputSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tokenValueInit: {},
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setTokenValue(state, action) {
      state.tokenValueInit = action.payload;
    },
  },
});

export const { setTokenValue } = tokenSlice.actions;

export default tokenSlice.reducer;
