/* eslint-disable prettier/prettier */
import * as S from './EmployeeTable.styles';

import { DeleteOutlined, EditOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, DatePicker, Input, Popover, Space, Table, message } from 'antd';
import { IconDelEmployeeSVG } from '@app/assets/svg';
import { useContext, useRef, useState } from 'react';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { Language, Skill } from './Data/DataFilter';
import Highlighter from 'react-highlight-words';
import type { InputRef } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import ModelInsert from '@app/components/list-employee/List/component/ModelInsert/ModelInsert';
import { deleteEmployeeService } from '@app/services/employee.service';
import { EmployeeList, IEmployeeParams } from '@app/types/employee';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

import { EmployeeContext } from '@app/components/list-employee/List/ListEmployeeInBul';
import {
  dataJobTitlesTranform,
  dataLanguagesTranform,
  dataSeniorityTranform,
  dataSitesTranform,
  dataSkillsTranform,
} from '@app/constants/defaultOption';

interface EmployeeTableType {
  dataListEmployee: EmployeeList[] | [];
  onDoneList: () => void;
}
export const EmployeeTable = ({ onDoneList, dataListEmployee }: any) => {
  const searchInput = useRef<InputRef>(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [dialogType, setDialogType] = useState<'Add' | 'Edit'>('Add');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRecords, setExpandedRecords] = useState<Record<string, boolean>>({});
  const { getListAllDepartment } = useContext(EmployeeContext);

  const transFormDataTableListCandidate = (data: any) => {
    const dataTransform = data?.map((itemCV: any) => {
      const firstJobTitle = itemCV && itemCV?.list_cv?.length > 0 ? itemCV?.list_cv[0]?.job_title : '';
      const firstSeniority = itemCV && itemCV?.list_cv?.length > 0 ? itemCV?.list_cv[0]?.seniority : '';
      const firstSKils = itemCV && itemCV?.list_cv?.length > 0 ? itemCV?.list_cv[0]?.skills?.toString() : '';
      const urlCV = itemCV && itemCV?.list_cv?.length > 0 ? itemCV?.list_cv[0]?.url : undefined;
      const languages = itemCV && itemCV?.languages?.length > 0 ? itemCV?.languages[0]?.language?.toString() : '';
      return {
        _id: itemCV._id,
        index: itemCV.index as number,
        account: itemCV?.account,
        full_name: itemCV?.full_name,
        languages: languages,
        job_title: firstJobTitle,
        skill: firstSKils,
        seniority: firstSeniority,
        site: itemCV?.site,
        department: itemCV?.department,
        total_hours_day: itemCV?.total_hours_day,
        total_calendar_effort: itemCV?.total_calendar_effort,
        contract_date: itemCV?.contract_date as string,
        dob: itemCV?.dob as string,
        url: urlCV,
        url_avatar: itemCV?.avatar?.url,
        supervisor: itemCV?.supervisor ? itemCV?.supervisor?.account : null,
        supervisor_obj: itemCV?.supervisor ? itemCV?.supervisor : null,
      };
    });
    return dataTransform;
  };

  const dataTransform = transFormDataTableListCandidate(dataListEmployee);

  const modifiedData = getListAllDepartment?.slice(1)?.map((item: any) => ({
    text: item.title,
    key: item.key,
    value: item.value,
    children: item?.children?.map((childItem: any) => ({
      text: childItem.title,
      key: childItem.key,
      value: childItem.value,
    })),
  }));

  const dataDOB = [
    {
      text: 'January',
      value: '01',
    },
    {
      text: 'February',
      value: '02',
    },
    {
      text: 'March',
      value: '03',
    },
    {
      text: 'April',
      value: '04',
    },

    {
      text: 'May',
      value: '05',
    },
    {
      text: 'June',
      value: '06',
    },
    {
      text: 'July',
      value: '07',
    },
    {
      text: 'August',
      value: '08',
    },
    {
      text: 'September',
      value: '09',
    },

    {
      text: 'October',
      value: '10',
    },
    {
      text: 'November',
      value: '11',
    },
    {
      text: 'December',
      value: '12',
    },
  ];

  const [popoverStates, setPopoverStates] = useState<{ [key: string]: boolean }>({});

  const [dataUpdateEmployee, setDataUpdateEmployee] = useState<any>();

  const handleVisibleChange = (recordKey: string, newVisible: boolean) => {
    setPopoverStates((prevStates) => ({
      ...prevStates,
      [recordKey]: newVisible,
    }));
  };

  const handleHidePopover = (recordKey: string) => {
    setPopoverStates((prevStates) => ({
      ...prevStates,
      [recordKey]: false,
    }));
  };

  const handleDeleteEmployee = async (record: any) => {
    try {
      await deleteEmployeeService(record._id);
      message.success(`Delete employee ${record?.account} success`);
      onDoneList();
    } catch (error) {
      message.error('Delete Error');
    }
  };

  const showModalUpdateEmployee = (record: any) => {
    setIsModalOpen(true);

    const recordSelected = dataListEmployee.find((item: any) => item._id === record._id);
    setDataUpdateEmployee(recordSelected);

    setDialogType('Edit');
  };
  const handleOnCloseDialog = () => {
    setIsModalOpen(false);
    setDataUpdateEmployee(undefined);
  };
  interface DataType {
    index: string;
    account: string;
    full_name: string;
    job_title: string;
    skill: string;
    skill_rank: string;
    languages: string;
    site: string;
    department: string;
    effort: string;
    dob: string;
    contract_date: string;
    supervisor: string;
    // supervisor: {
    //   _id: string;
    //   account: string;
    //   avatar: {
    //     random_key: string;
    //     url?: string;
    //     filename: string;
    //   };
    // };
  }
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  type DataIndex = keyof DataType;
  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as any}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const toggleExpand = (recordIndex: string) => {
    setExpandedRecords((prev) => ({
      ...prev,
      [recordIndex]: !prev[recordIndex],
    }));
  };

  const columns: ColumnsType<any> = [
    {
      title: '#',
      dataIndex: 'index',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Account',
      dataIndex: 'account',
      ...getColumnSearchProps('account'),
      render: (text: string, record: any) => {
        return (
          <>
            <Link to={`/employee/${record?._id}`}>
              {record?.url_avatar !== undefined ? (
                <span>
                  <Avatar size="small" src={record?.url_avatar} style={{ marginRight: 10 }} />
                </span>
              ) : (
                <Avatar size="small" icon={<UserOutlined />} style={{ marginRight: 10 }} />
              )}

              <span style={{ color: '#40A9FF' }}>{text?.split('@')[0]}</span>
            </Link>
          </>
        );
      },
    },
    {
      title: 'Full name',
      dataIndex: 'full_name',

      ...getColumnSearchProps('full_name'),
    },
    {
      title: 'Supervisor',
      dataIndex: 'supervisor',
      ...getColumnSearchProps('supervisor'),
      render: (text, record) => {
        return (
          <>
            {record.supervisor && (
              <Link to={`/employee/${record?.supervisor_obj?._id}`}>
                {record?.supervisor_obj?.avatar?.url !== undefined ? (
                  <span>
                    <Avatar size="small" src={record?.supervisor_obj?.avatar?.url} style={{ marginRight: 10 }} />
                  </span>
                ) : (
                  <Avatar size="small" icon={<UserOutlined />} style={{ marginRight: 10 }} />
                )}

                <span style={{ color: '#40A9FF' }}>{text}</span>
              </Link>
            )}
          </>
        );
      },
    },
    {
      title: 'DOB',
      dataIndex: 'dob',
      filters: dataDOB,

      filterSearch: true,

      onFilter: (value: any, record) => record?.dob?.includes(value),
    },
    {
      title: 'Job Title',
      dataIndex: 'job_title',

      filters: dataJobTitlesTranform,
      // filterMode: 'tree',
      filterSearch: true,

      onFilter: (value: any, record) => record?.job_title?.includes(value),
    },
    {
      title: 'Skill',
      dataIndex: 'skill',
      width: 300,
      filters: dataSkillsTranform,

      filterSearch: true,
      onFilter: (value: any, record) => record?.skill?.includes(value),
      render: (text: string, record: any) => {
        const skills = text?.split(',');
        const isExpanded = expandedRecords[record.index];

        const displaySkills = isExpanded ? skills : skills?.slice(0, 3);

        return (
          <>
            {displaySkills?.join(', ')}
            {skills?.length > 3 && (
              <span style={{ display: 'flex' }}>
                <S.CustomButton type="link" onClick={() => toggleExpand(record.index)}>
                  {isExpanded ? 'See Less' : 'See More'}
                </S.CustomButton>
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'Seniority',
      dataIndex: 'seniority',

      filters: dataSeniorityTranform,
      filterSearch: true,
      onFilter: (value: any, record) => record?.seniority?.includes(value),
    },
    {
      title: 'Languages',
      dataIndex: 'languages',

      filters: dataLanguagesTranform,
      filterSearch: true,
      onFilter: (value: any, record) => record?.languages?.includes(value),
    },
    {
      title: 'Site',
      dataIndex: 'site',

      filters: dataSitesTranform,
      filterSearch: true,
      onFilter: (value: any, record) => record?.site?.includes(value),
    },
    {
      title: 'Department',
      dataIndex: 'department',

      filters: modifiedData,
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value: any, record) => record?.department?.includes(value),
    },
    {
      title: 'Hours/day',
      dataIndex: 'total_hours_day',
      sorter: (a, b) => a?.total_hours_day - b?.total_hours_day,
      render: (total_hours_day: string) => {
        return (
          <>
            <S.Span>{total_hours_day}</S.Span>
          </>
        );
      },
    },

    {
      title: 'Calendar Effort',
      dataIndex: 'total_calendar_effort',
      sorter: (a, b) => a?.total_calendar_effort - b?.total_calendar_effort,
      render: (total_calendar_effort: string) => {
        return (
          <>
            <S.Span>{Number.parseFloat(total_calendar_effort ? total_calendar_effort : '0').toFixed(2)}</S.Span>
          </>
        );
      },
    },
    {
      title: 'Contract date',
      dataIndex: 'contract_date',

      filters: dataDOB,

      filterSearch: true,

      onFilter: (value: any, record) => record?.contract_date?.includes(value),
    },
    {
      title: 'Actions',

      width: 100,
      render: (record: any) => {
        const recordKey = record?.index?.toString();
        return (
          <S.ActiceWrapStyle>
            {record.url && (
              <Button
                style={{
                  color: 'rgb(64, 169, 255)',
                  padding: '5px',
                  marginTop: '2px',
                  display: 'inline-block',
                }}
                target="_blank"
                href={record.url}
                type="link"
              >
                CV
              </Button>
            )}
            <BaseButton
              type="text"
              onClick={() => {
                showModalUpdateEmployee(record);
              }}
            >
              <EditOutlined style={{ color: '#52C41A' }} />
            </BaseButton>
            <Popover
              open={popoverStates[recordKey]}
              onOpenChange={(newVisible) => handleVisibleChange(recordKey, newVisible)}
              placement="bottomRight"
              content={content(recordKey, record)}
              trigger="click"
            >
              <DeleteOutlined style={{ color: '#FF7875' }} />
            </Popover>
          </S.ActiceWrapStyle>
        );
      },
    },
  ];

  const content = (recordKey: string, record: any) => (
    <div>
      <IconDelEmployeeSVG /> <S.SpanDel>Are you sure delete this item?</S.SpanDel>
      <S.WrapperButton>
        <S.CustomButtonNo size="middle" onClick={() => handleHidePopover(recordKey)}>
          No
        </S.CustomButtonNo>
        <S.CustomButtonYes size="middle" onClick={() => handleDeleteEmployee(record)}>
          Yes
        </S.CustomButtonYes>
      </S.WrapperButton>
    </div>
  );

  return (
    <>
      <S.CustomTable>
        <Table
          rowKey={'index'}
          columns={columns}
          dataSource={dataTransform ? dataTransform : []}
          size="small"
          pagination={{
            defaultPageSize: 20,

            position: ['bottomLeft'],
            showSizeChanger: false,
          }}
          className="custom-table"
          // loading={isData.loading || isLoadingAdd?.loading || isLoadingUpdate?.loading}
          scroll={{ x: 'max-content' }}
          bordered
        />
      </S.CustomTable>

      {dataUpdateEmployee && (
        <ModelInsert
          type={dialogType}
          isModalOpen={isModalOpen}
          onCloseDialog={handleOnCloseDialog}
          onDone={onDoneList}
          data={dataUpdateEmployee}
        />
      )}
    </>
  );
};
