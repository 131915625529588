export interface ICvManagement {
  _id: string;
  job_title: string;
  skills: string;
  seniority: string;
  create_time: string;
  account: string;
  note: string;
  url: string;
  random_key?: string;
  name_file?: string;
  candidate_id: string;
  filename?: string;
}
export interface INote {
  _id: string;
  notes: string;
  account: string;
  created_on: string;
  create_by: string;
  updated_on: string;
  update_by: string;
}

export interface IEducation {
  school_name: string;
  field_of_study: string;
}

export interface ILanguage {
  language: string;
  level: string;
}

export interface ICertificate {
  certificate: string;
}

export interface IAward {
  award: string;
}

export interface IExperience {
  duration: string[];
  companyName: string;
  projectName: string;
  teamSize: string;
  position: string;
  responsibility: string;
  technology: string;
  description: string;
}
export interface IPicInterview {
  account: string;
  email: string;
}
export interface IJobTites {
  jobTitle: string;
  seniority: string;
  skills: string[];
}
export interface IEmployeeParams {
  account?: string;
  full_name: string;
  contact_date: string;
  site?: string;
  jobTiltes?: IJobTites[];
  upload_file: any;
  date?: string;
  picInterview?: IPicInterview[];
  note?: string;
  dob: string;
  contract_date: string;
  candidate_id: string | null;
  description?: string;
  educations?: IEducation[];
  languages?: ILanguage[];
  certificates?: ICertificate[];
  honors_awards?: IAward[];
  experiences?: IExperience[];
  supervisor?: string;
}
export interface TableColumn {
  title: string;
  dataIndex?: keyof UserData; // Make it optional
  key: string;
  width?: string;
  render?: (record: UserData) => JSX.Element;
}

export interface UserData {
  _id: string;
  full_name: string;
  job_title: string;
  job_rank: string;
  site: string;
  department: string;
}
export interface ICV {
  _id: string;
  seniority: string;
  skills: string[];
  jobTitle: string;
  note?: string;
  url?: string;
  random_key?: string;
  filename?: string;
}
export interface ILanguage {
  language: string;
  level: string;
}
export interface ICertificate {
  certificate: string;
}
export interface IAward {
  award: string;
}

export interface IExperience {
  duration: string[];
  companyName: string;
  projectName: string;
  teamSize: string;
  position: string;
  responsibility: string;
  technology: string;
  description: string;
}
export interface ICV {
  _id: string;
  seniority: string;
  skills: string[];
  job_title: string;
  note?: string;
  url?: string;
  random_key?: string;
  filename?: string;
}

//type

interface Education {
  school_name: string;
  field_of_study: string;
}

interface Certificate {
  certificate: string;
}

interface Project {
  project_name: string;
  job_title: string[];
  description: string;
  skills: string[];
}

interface Language {
  language: string;
  level: string;
}

interface Experience {
  companyName: string;
  projectName: string;
  duration: string[];
  teamSize: string;
  position: string;
  responsibility: string;
  technology: string;
  description: string;
}

interface Note {
  notes: string;
  _id: string;
  random_key: string;
  account: string;
  created_on: string;
  updated_on: string;
}

interface HonorsAward {
  award: string;
}

export interface EmployeeList {
  _id: string;
  name: string;
  description: string;
  skills: string[];
  potential_job_title: string[];
  educations: Education[];
  certificates: Certificate[];
  projects: Project[];
  list_cv: {
    _id: string;
    skills: string[];
    random_key: string;
    url: string;
    filename: string;
    job_title: string;
    seniority: string;
    note?: string;
    updated_on?: string;
    created_on?: string;
  }[];
  created_on: string;
  updated_on: string;
  existed: boolean;
  full_name: string;
  account: string;
  dob: string;
  contract_date: string;
  department: string;
  effort: number | string;
  project_name: string;
  site: string;
  languages: Language[];
  list_note: Note[];
  experiences: Experience[];
  index: number;
  honors_awards: HonorsAward[];
  total_calendar_effort: string;
  total_hours_day: string;
}
export interface ResourceItem {
  _id: string;
  project_name: string;
  description: string;
  start_date: string;
  due_date: string;
  role: string;
  hours_day: string;
  calendar_effort: number;
  random_key: string;
  created_on: string;
  updated_on: string;
}
export enum EStatusEmployee {
  ACTIVE = 'Active',
  INACTIVE = 'InActive',
}

export interface Employee {
  _id: string;
  account: string;
  full_name: string;
  department: string;
  status: string;
  existed: boolean;
  list_note: never[]; // Assuming list_note is an array of a specific type, replace 'never' with the actual type
  list_project: Project[];
  list_cv: never[]; // Assuming list_cv is an array of a specific type, replace 'never' with the actual type
  dob: string;
  contract_date: string;
  hiring_for_project_name: string;
  languages: Language[];
  site: string;
  total_hours_day: number;
  total_calendar_effort: number;
}

interface Project {
  _id: string;
  role: string;
  hours_day: number;
  calendar_effort: number;
  start_date: string;
  due_date: string;
  created_on: string;
  updated_on: string;
  project_name: string;
  project_code: string;
  customer: string;
  project_lead: string;
  department: string;
  status: string;
  cost: string;
  delivery: string;
  bmm: string;
  start_date_pro: string;
  start_due_date: string;
}

interface Language {
  language: string;
  level: string;
}

export interface EmployeePreview {
  account: string;
  full_name: string;
  dob: number;
  job_title: string;
  skill: string;
  seniority: string;
  language: string;
  site: string;
  department: string;
  contract_date: string;
  hiring_for_project_name: string;
  index: number;
}

export interface IGetAllEmployeeParams {
  get_all_account: boolean;
}
export interface EmployeeExport {
  Account: string;
  FullName: string;
  Bod?: string;
  Job_title: string | number;
  Skill: string | number;
  Seniority: string | number;
  Site?: string;
  Department: string;
  HoursDay: string | number;
  Calendar_Effort: string | number;
  Contract_Data?: string;
}
