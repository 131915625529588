import { createSelector } from '@reduxjs/toolkit';
import { State } from './type';

const selectSelf = (state: any) => state.department;

export const selector = {
  selectEmployeeData: createSelector(selectSelf, (state) => state),

  selectList: createSelector(selectSelf, (state) => state.list),
  selectListByAcc: createSelector(selectSelf, (state) => state.listbyacc),
  selectItem: createSelector(selectSelf, (state) => state.item),
};
