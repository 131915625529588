import { Button } from 'antd';
import styled from 'styled-components';

export const CustomButtonTemplate = styled(Button)`
  color: #1890ff;
  position: absolute;
  right: 0;
  bottom: 0;
`;
export const CustomDivTemplate = styled.div`
  position: relative;
`;
