import React from 'react';
import { Link } from 'react-router-dom';

import * as S from './ProfileOverlay.styles';
import { IconBookSVG, IconPendingSVG, IconUsersSVG, IconVectorSVG } from '@app/assets/svg';
import api from '@app/api';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { setTokenValue } from '@app/store/token/tokenSlice';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();
  const dataToken = useSelector((state: any) => state.token);

  const isAdmin = dataToken?.tokenValueInit?.roles;

  const onLogout = () => {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: '/',
    };

    instance.logoutPopup(logoutRequest).catch((e: any) => {
      console.error(e);
    });
    api.removeToken();
    localStorage.clear();
    sessionStorage.clear();
    dispatch(setTokenValue({}));
  };
  return (
    <div {...props}>
      {isAdmin && isAdmin.includes('ADMIN') && (
        <>
          <S.Text>
            <IconUsersSVG />
            <Link to="/admin" style={{ color: '#000000' }}>
              Users
            </Link>
          </S.Text>
          <S.ItemsDivider />
        </>
      )}
      <S.Text>
        <IconVectorSVG />
        <Link to="/" style={{ color: '#000000' }} onClick={onLogout}>
          Sign out
        </Link>
      </S.Text>
    </div>
  );
};
