import React from 'react';
import './styles.css';

const DashboardEmployee = () => {
  return (
    <div>
      <iframe
        src="https://app.powerbi.com/reportEmbed?reportId=9f6d8d78-2a5a-4a63-aa49-faaccb40840a&autoAuth=true&ctid=f01e930a-b52e-42b1-b70f-a8882b5d043b"
        frameBorder="0"
        allowFullScreen={true}
        style={{
          width: '100%',
          height: '100vh',
        }}
      ></iframe>
    </div>
  );
};

export default DashboardEmployee;
