import themeReducer from '@app/store/slices/themeSlice';
import searchInputReducer from '@app/store/status/statusSlice';
import { reducer as EmployeeReducer } from './employee';
import { reducer as EmployeeInRoleReducer } from './employeeinrole';
import { reducer as AdminReducer } from './admin';
import { reducer as DepartmentReducer } from './deparment';
import { reducer as ResourceReducer } from './resource';
import { reducer as uploadReducer } from './upload';
import tokenReducer from '@app/store/token/tokenSlice';
import statusReducer from '@app/store/status/statusSlice';
import breadcumbsReducer from '@app/store/breadcumbs/breadcumbsSlice';

export default {
  theme: themeReducer,
  searchInput: searchInputReducer,
  token: tokenReducer,
  status: statusReducer,
  employee: EmployeeReducer,
  employeeinrole: EmployeeInRoleReducer,
  admin: AdminReducer,
  department: DepartmentReducer,
  resource: ResourceReducer,
  upload: uploadReducer,
  breadcumbs: breadcumbsReducer,
};
