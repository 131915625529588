import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { createAsyncAction } from '@app/services/thunkWrapper';
import { ActionParams } from '../types/common';

export const importData = createAsyncThunk(
  'import/importData',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/upload_excel`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);
export const previewUpload = createAsyncThunk(
  'preview/previewUpload',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/preview_upload`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const uploadCv = createAsyncThunk(
  'upload/uploadCV',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/upload_cv/${payload.params.employee_id}`;

    const response = await api.caller.post(endpoint, payload.params.fileUpload);
    const data = response.data;

    return data;
  }),
);

export const previewUploadJira = createAsyncThunk(
  'preview/previewUploadJira',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/jira/preview`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);
export const uploadDataJira = createAsyncThunk(
  'upload/uploadDataJira',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/jira/upload`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const updateCVEmployee = createAsyncThunk(
  'upload/updateCV',
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/upload_cv/${payload?.params?.employee_id}/${payload?.params?.employee_cv_id}`;

    const response = await api.caller.put(endpoint, payload.params?.fileUpload);
    const data = response.data;

    return data;
  }),
);
