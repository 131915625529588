import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { useMsal } from '@azure/msal-react';
import { useSelector } from 'react-redux';

export const ProfileDropdown: React.FC = () => {
  const { instance, accounts } = useMsal();
  const dataToken = useSelector((state: any) => state.token);
  const role = dataToken?.tokenValueInit?.roles;
  return (
    <BasePopover placement="topLeft" content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          <BaseAvatar alt="User" shape="circle" size={40} style={{ marginBottom: 20 }} />
        </BaseCol>
        <BaseCol style={{ width: 200 }}>
          <S.BrandSpanFooter>{accounts[0]?.name}</S.BrandSpanFooter>
          <S.CustomP>{role?.toString()}</S.CustomP>
        </BaseCol>
      </S.ProfileDropdownHeader>
    </BasePopover>
  );
};
