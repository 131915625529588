import React from 'react';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import * as S from '../Header.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoImage from '../../../assets/logoVector.svg';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}
export const SiderLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
`;
export const BrandSpan = styled.span`
  margin: 0 1rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--color-hover);
  line-height: 1;
`;
export const CustomLogo = styled.div`
  width: 200px;
  margin-bottom: 30px;
`;

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={16} xxl={17}>
      <BaseRow justify="space-between">
        <BaseCol xl={15} xxl={12}>
          <CustomLogo>
            <SiderLogoLink to="/dashboard">
              <img src={logoImage} alt="Lightence" />
              <BrandSpan>Candidate Recommendation</BrandSpan>
            </SiderLogoLink>
          </CustomLogo>
        </BaseCol>
      </BaseRow>
    </S.SearchColumn>
  ) : (
    <>
      <BaseCol lg={10} xxl={8}>
        <CustomLogo>
          <SiderLogoLink to="/dashboard">
            <img src={logoImage} alt="Lightence" />
            <BrandSpan>Candidate Recommendation</BrandSpan>
          </SiderLogoLink>
        </CustomLogo>
      </BaseCol>
    </>
  );

  return (
    <BaseRow justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={8} xxl={7} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <BaseRow align="middle" justify="end" gutter={[5, 5]}>
          <ProfileDropdown />
        </BaseRow>
      </S.ProfileColumn>
    </BaseRow>
  );
};
