// searchInputSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  statusValue: '',
  statusDelete: '',
  statusAdd: '',
  statusActive: '',
  statusFile: {},
  statusProjectRole: '',
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus(state, action) {
      state.statusValue = action.payload;
    },
    setStatusDelete(state, action) {
      state.statusValue = action.payload;
    },
    setStatusAdd(state, action) {
      state.statusAdd = action.payload;
    },
    setStatusActive(state, action) {
      state.statusActive = action.payload;
    },
    setStatusFile(state, action) {
      state.statusFile = action.payload;
    },
    setStatusProjectRole(state, action) {
      state.statusProjectRole = action.payload;
    },
  },
});

export const {
  setStatus,

  setStatusDelete,
  setStatusAdd,
  setStatusActive,
  setStatusFile,
  setStatusProjectRole,
} = statusSlice.actions;

export default statusSlice.reducer;
