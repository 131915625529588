import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActionParams } from '../types/common';
import api from '@app/api';
import { createAsyncAction } from '@app/services/thunkWrapper';
import { buildUrl } from '@app/services/app';

export const getListEmployeeInDepartment = createAsyncThunk(
  'get/get_list_employeeInDepart',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/project/employee_options`, payload.params);
    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);

export const addUserEmployee = createAsyncThunk(
  'add/user_employee',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/insert_record`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;
    return data;
  }),
);

export const getDetailEmployee = createAsyncThunk(
  'detail/detail_employee',

  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/record/${payload.params}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);

export const updateEmployee = createAsyncThunk(
  'update/update_employee',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/update_record`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const removeEmployee = createAsyncThunk(
  'remove/remove_employee',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/record/${payload.params}`;
    const response = await api.caller.delete(endpoint);
    const data = response.data;

    return data;
  }),
);

export const searchEmployee = createAsyncThunk(
  'search/search_employee',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/search_employee?search_word=${payload.params}`;
    const response = await api.caller.get(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const searchEmployeeResource = createAsyncThunk(
  'search/search_employee_resource',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/search_employee?search_word=${payload.params}`;
    const response = await api.caller.get(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const getTitle = createAsyncThunk(
  'get/get_title',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/title_list`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  }),
);

export const getRecordEmployee = createAsyncThunk(
  'get/get_record_employee',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/record/${payload.params}`;
    const response = await api.caller.get(endpoint);
    const data = response.data;

    return data;
  }),
);

export const uploadCVautoFill = createAsyncThunk(
  'upload/upload_cv',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/autofill/autofill`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const listAllProject = createAsyncThunk(
  'list/list_all',
  createAsyncAction(async (payload: ActionParams) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/resource-allocation/all_project_name`;
    const response = await api.caller.get(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);
