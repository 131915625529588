import { Button, Table } from 'antd';
import styled from 'styled-components';

// export const CustomTable = styled(Table)`
//   & thead .ant-table-cell {
//     color: white;
//     background-color: #9254de;

//     line-height: 1.25rem;
//   }
//   .ant-table-tbody tr:nth-child(even) {
//     background-color: #ffffff;
//   }

//   .ant-table-tbody tr:nth-child(odd) {
//     background-color: #f5f5f5;
//   }
//   .ant-table.ant-table-bordered > .ant-table-container {
//     border-left: 1px solid #f0f0f0;
//     border-radius: 8px;
//     border: 1px solid #f5f5f5;
//     overflow: hidden;
//   }

//   span.anticon.anticon-search {
//     color: white;
//   }
//   .ant-table-filter-trigger.active {
//     color: white;
//   }
//   .ant-dropdown-trigger .ant-table-filter-trigger .active {
//     color: white;
//   }
// `;
export const WrapperButton = styled.div`
  text-align: end;
  margin: 10px 0px;
`;

export const SpanDel = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #000000;
`;

export const CustomButtonNo = styled(Button)`
  border: 1px solid #ff4d4f;
  border-radius: 4px;
  /* padding: 15px 1px; */
  width: 50px;
  background-color: white;
  color: #a8071a;
`;
export const CustomButtonYes = styled(Button)`
  border: 1px solid #ff4d4f;
  border-radius: 4px;
  /* padding: 15px 1px; */
  width: 50px;
  background-color: #ff4d4f;
  color: white;
  margin-left: 10px;
`;
export const CustomEffort3 = styled.div`
  width: 51px;
  height: 30px;
  border-radius: 100px;
  padding: 12px 0px;
  background-color: #d9f7be;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomEffort1 = styled.div`
  width: 51px;
  height: 30px;
  border-radius: 100px;
  padding: 12px 0px;
  background-color: #ffe7ba;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomEffort2 = styled.div`
  width: 51px;
  height: 30px;
  border-radius: 100px;
  padding: 12px 0px;
  background-color: #ffccc7;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CustomButtonCV = styled(Button)`
  text-align: start;
  padding-left: 0px;
`;
export const CustomTable = styled('div')`
  thead .ant-table-cell {
    color: white;
    background-color: #9254de;

    line-height: 1.25rem;
    .anticon {
      color: #ffffff;
    }
    .ant-dropdown-trigger.active .anticon {
      color: rgb(24, 144, 255);
    }
  }
  .ant-table-tbody tr:nth-child(even) {
    background-color: #ffffff;
  }

  .ant-table-tbody tr:nth-child(odd) {
    background-color: #f5f5f5;
  }
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: 1px solid #f0f0f0;
    border-radius: 8px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
  }

  .ant-table-column-sorters.ant-tooltip-open {
    background-color: #9254de;
  }
  th.ant-table-cell.ant-table-column-has-sorters:hover {
    background-color: #9254de;
  }

  .ant-table-column-sorters::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
  .ant-table-column-sorters::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* content: ''; */
  }
  span.ant-table-column-sorter.ant-table-column-sorter-full {
    color: white !important;
  }
  .ant-table-column-sorter {
    margin-left: 4px;

    font-size: 0;
    transition: color 0.3s;
    color: white !important;
  }
`;
export const ActiceWrapStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const CustomButton = styled(Button)`
  text-align: start;
  margin-left: -15px;
`;
export const Span = styled.span`
  display: flex;
  justify-content: end;
`;
