/* eslint-disable @typescript-eslint/no-empty-function */
import * as S from './ListInEmployee.styles';
import './styles.css';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { EmployeeTable } from '@app/components/tables/EmployeeTable/EmployeeTable';
import type { ColumnsType } from 'antd/es/table';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import ModelInsert from '../ModelInsert/ModelInsert';
import { Button, DatePicker, Form, Modal, Upload, message } from 'antd';
import { BackIcon, IconImportFileSVG, IconSearchEmployeeSVG } from '@app/assets/svg';
import { useNavigate } from 'react-router-dom';
import ExportToExcel from '../ExportExel/ExportExel';
import DowloadTemplate from '../DowloadTemplate/DowloadTemplate';
import { dataExpamle } from './Data/DataInput';
import { getListEmployeeService, importFileEmployee, importFileEmployeePreview } from '@app/services/employee.service';
import { EStatusEmployee, Employee, EmployeeList, EmployeePreview } from '@app/types/employee';
import type { DatePickerProps } from 'antd';
import moment from 'moment';

const ListInEmployee = ({ status }: { status: EStatusEmployee }) => {
  const now = new Date();
  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [datapreview, setDataPreview] = useState<any>();

  const navigate = useNavigate();
  const monthFormat = 'MM/YYYY';
  const [dialogType, setDialogType] = useState<'Add' | 'Edit'>('Add');
  const [showPrefix, setShowPrefix] = useState(true);

  const [dataFilter, setDataFilter] = useState<any>({ status: status, time: `${currentMonth}/${currentYear}` });
  const [newDataPreview, setNewDataPreview] = useState<EmployeePreview[]>();

  const [idFile, setIdFile] = useState();
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingPreview, setIsLoadingPreiew] = useState<boolean>(false);
  const [dataListEmployee, setDataListEmployee] = useState<EmployeeList[]>();

  const customDataExport = dataListEmployee?.map((item: EmployeeList) => {
    return {
      Account: item?.account,
      FullName: item?.full_name,
      Bod: item?.dob,
      Job_title: item?.list_cv?.length && item?.list_cv?.[0]?.job_title,
      Skill: item?.list_cv?.length && item?.list_cv?.[0]?.skills?.toString(),
      Seniority: item?.list_cv?.length && item?.list_cv?.[0]?.seniority,
      Languages: item?.languages?.length && item?.languages?.[0]?.language,
      Site: item?.site ? `${item?.site}` : '',
      Department: `${item?.department}`,
      HoursDay: `${item?.total_hours_day}`,
      Calendar_Effort: `${item?.total_calendar_effort}`,
      Contract_Data: item?.contract_date ? `${item?.contract_date}` : '',
    };
  });
  interface DataType {
    key: string;
    index: string;
    account: string;
    full_name: string;
    dob: string;
    job_title: string;
    skill: string;
    seniority: string;
    site: string;
    language: string;
    department: string;
    contract_date: string;
    hiring_for_project_name: string;
  }
  const dataImport: any[] = [
    {
      title: '#',
      dataIndex: 'index',
      width: '4%',
    },
    {
      title: 'Account',
      dataIndex: '',

      key: '',
      render: (record: any) => {
        return (
          <>
            <span className="">{record?.account}</span>
          </>
        );
      },
    },
    {
      title: 'Full name',
      dataIndex: '',

      render: (record: any) => {
        return (
          <>
            <span className="">{record?.full_name}</span>
          </>
        );
      },
    },
    {
      title: 'DOB',
      dataIndex: '',

      render: (record: any) => {
        return (
          <>
            <span className="">{record?.dob}</span>
          </>
        );
      },
    },

    {
      title: 'Job Title',
      dataIndex: '',

      key: '',
      render: (record: any) => {
        return (
          <>
            <span className="">{record?.job_title}</span>
          </>
        );
      },
    },
    {
      title: 'Skill',
      dataIndex: '',

      key: '',
      render: (record: any) => {
        return (
          <>
            <span className="">{record?.skill}</span>
          </>
        );
      },
    },
    {
      title: 'Seniority',
      dataIndex: '',

      key: '',
      render: (record: any) => {
        return (
          <>
            <span className="">{record?.seniority}</span>
          </>
        );
      },
    },
    {
      title: 'Language',
      dataIndex: '',

      key: '',
      render: (record: any) => {
        return (
          <>
            <span className="">{record?.language}</span>
          </>
        );
      },
    },
    {
      title: 'Site',
      dataIndex: '',

      key: '',
      render: (record: any) => {
        return (
          <>
            <span className="">{record?.site}</span>
          </>
        );
      },
    },

    {
      title: 'Department',
      dataIndex: '',
      key: '',

      render: (record: any) => {
        return (
          <>
            <span className="">{record?.department}</span>
          </>
        );
      },
    },
    {
      title: 'ContractDate',
      dataIndex: '',
      key: '',

      render: (record: any) => {
        return (
          <>
            <span className="">{record?.contract_date}</span>
          </>
        );
      },
    },
    {
      title: 'Hiring for Project name',
      dataIndex: '',
      key: '',

      render: (record: any) => {
        return (
          <>
            <span className="">{record?.hiring_for_project_name}</span>
          </>
        );
      },
    },
  ];
  const fileHandlerChange = (file: any) => {
    setDataPreview(file?.file);
  };
  const onRemoveFile = () => {
    setNewDataPreview([]);
  };
  const getDataPreview = async () => {
    const formData = new FormData();
    const params = {
      flag: 'preview',
    };
    formData.append('file', datapreview);
    try {
      setIsLoadingPreiew(true);
      const response = await importFileEmployeePreview(params, formData);
      setNewDataPreview(response?.data?.employee_table);
      setIdFile(response?.data?._id);
      setIsLoadingPreiew(false);
    } catch (error: any) {
      Modal.error({
        title: (
          <span
            style={{ fontWeight: 'bold' }}
          >{`${error?.response?.data?.detail}, Please re-upload the file with the following template:`}</span>
        ),

        content: <S.CustomTablePreview size="small" columns={dataImport} dataSource={dataExpamle} pagination={false} />,
        className: 'centered-button-modal',
        width: 1000,
        okText: 'Upload Again',

        onOk: () => {
          setNewDataPreview([]);
          setDataPreview(null);
        },
      });
    }
  };

  useEffect(() => {
    datapreview && getDataPreview();
  }, [datapreview]);

  const propsUpload: any = {
    onChange: fileHandlerChange,
    onRemove: onRemoveFile,
    multiple: false,
    maxCount: 1,
    beforeUpload: (file: any) => {
      const isJpgOrPng =
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'text/csv';
      if (!isJpgOrPng) {
        message.error(`${file.name} Import file format is not correct template.`);
      }

      return false;
    },
    showUploadList: false,
  };

  const hanldeSubmit = async () => {
    const formData = new FormData();
    formData.append('file', datapreview);
    if (isUploading) {
      return;
    }
    const params = {
      flag: 'update',
      id_table: idFile,
    };

    try {
      setIsUploading(true);
      idFile && (await importFileEmployee(params));
      message.success('import succcess');
      setDataPreview(null);
      setNewDataPreview([]);
      setIsUploading(false);
    } catch (error: any) {
      console.log('error', error?.response?.data?.detail);
    }
  };

  const hanldeSearchEmployee = (e: any) => {
    if (e.key === 'Enter') {
      setDataFilter({
        ...dataFilter,
        keyword: e.target.value,
      });
    }
    setShowPrefix(e.target.value === '');
  };
  const hanldeChangeEmployee = (e: any) => {
    setShowPrefix(e.target.value === '');
  };

  const showModal = () => {
    setIsModalOpen(true);
    setDialogType('Add');
  };

  const handleOnCloseDialog = () => {
    setIsModalOpen(false);
  };

  const getUserlist = async () => {
    try {
      setIsLoading(true);
      const response = await getListEmployeeService({ params: dataFilter });
      const addIndex = response.data.map((item: Employee, index: number) => {
        return { ...item, index: index + 1 };
      });

      setDataListEmployee(addIndex);
      setIsLoading(false);
    } catch (error: any) {
      console.log('error', error);
      setIsLoading(false);
      message.error(error?.response?.data?.detail ? error?.response?.data?.detail : 'Something went wrong');
    }
  };

  useEffect(() => {
    getUserlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter, datapreview, status]);

  const hanldeBack = () => {
    setDataPreview(null);
    setNewDataPreview([]);
    navigate('/employee');
  };
  const onCancel = () => {
    setDataPreview(null);
    setNewDataPreview([]);
    navigate('/employee');
  };

  const getCustomPopupContainer = (node: any) => {
    return node.parentNode;
  };
  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setDataFilter({
      ...dataFilter,
      time: dateString,
    });
  };

  useEffect(() => {
    return () => {
      setIsUploading(false);
    };
  }, [isUploading]);

  return (
    <>
      {datapreview ? (
        <S.CustomDivFile>
          <S.ControlNavigate>
            <span>
              <Button type="text" onClick={hanldeBack}>
                <BackIcon />
              </Button>
            </span>
            <span>
              <h1>Back to Employee</h1>
            </span>
          </S.ControlNavigate>
          {isUploading ? (
            <S.LoadingCustom>
              <S.ControlSpin>
                <BaseSpin></BaseSpin>
              </S.ControlSpin>
            </S.LoadingCustom>
          ) : (
            ''
          )}
          <Form form={form} onFinish={hanldeSubmit}>
            {datapreview && newDataPreview && newDataPreview.length > 0 && (
              <S.CustomTable
                rowKey={(o: any) => o?.index}
                columns={dataImport}
                dataSource={newDataPreview}
                loading={isLoadingPreview}
                style={{ width: '100%', height: '100%' }}
                size="small"
                pagination={false}
              />
            )}

            <S.PostionBtn>
              {datapreview && newDataPreview && newDataPreview.length > 0 && (
                <>
                  <S.ButtonCancel onClick={onCancel}>Upload again</S.ButtonCancel>
                  <S.ButtonSubmit htmlType="submit">Save and upload</S.ButtonSubmit>
                </>
              )}
            </S.PostionBtn>
          </Form>
        </S.CustomDivFile>
      ) : (
        <S.WrapControl>
          <>
            <S.DivControl>
              <S.DivControlLeft>
                <S.CustomInput
                  size="large"
                  onKeyPress={(e) => hanldeSearchEmployee(e)}
                  onChange={(e) => hanldeChangeEmployee(e)}
                  placeholder="Search"
                  prefix={showPrefix ? <IconSearchEmployeeSVG /> : null}
                  style={{ width: '60%' }}
                  allowClear
                />

                <DatePicker
                  onChange={onChangeDate}
                  picker="month"
                  defaultValue={moment(`${currentMonth}/${currentYear}`, monthFormat)}
                  format={monthFormat}
                  style={{ marginLeft: 10, borderRadius: 8 }}
                  size="large"
                  getPopupContainer={getCustomPopupContainer}
                />
              </S.DivControlLeft>
              <div>
                {' '}
                <Upload {...propsUpload} accept=".xlsx , .csv">
                  <S.CustomButtonImport size="large" icon={<IconImportFileSVG />}>
                    Import file
                  </S.CustomButtonImport>
                </Upload>
                <ExportToExcel dataExport={customDataExport} />
                <S.CustomButton onClick={showModal} size="large" icon={<PlusOutlined />}>
                  Insert record
                </S.CustomButton>
              </div>
            </S.DivControl>

            {isLoading ? (
              <S.ControlSpin>
                <BaseSpin></BaseSpin>
              </S.ControlSpin>
            ) : (
              <div>
                <EmployeeTable dataListEmployee={dataListEmployee} onDoneList={getUserlist} />
                <DowloadTemplate />
              </div>
            )}

            <ModelInsert
              type={dialogType}
              onDone={getUserlist}
              isModalOpen={isModalOpen}
              onCloseDialog={handleOnCloseDialog}
            />
          </>
        </S.WrapControl>
      )}
    </>
  );
};

export default ListInEmployee;
