import axios, { AxiosInstance } from 'axios';
// declare global {
//   interface Window {
//     abortController: any;
//   }
// }

// window.abortController = new AbortController();
class Api {
  public caller!: AxiosInstance;

  static instance?: Api;

  static getInstance() {
    if (!this.instance) this.instance = new Api();
    return this.instance;
  }

  constructor() {
    this.init();
  }

  private init() {
    this.caller = axios.create({
      headers: {
        // 'Content-type': 'application/json',
        // responseType: 'blob',
        'Ocp-Apim-Subscription-Key': 'c9e236a267f54e5387e0c0a3b73239a6',
      },
    });
    // - Token
    const token = localStorage.getItem('Candidate');

    // eslint-disable-next-line
    if (token) this.caller.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // - Config
    this.caller.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    this.caller.defaults.timeout = 1000 * 60 * 5;
    // this.caller.defaults.signal = window.abortController.signal;
  }

  //> Handle
  public setToken(token: string) {
    localStorage.setItem('Candidate', token);

    // eslint-disable-next-line
    if (token) this.caller.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  public removeToken() {
    localStorage.removeItem('Candidate');
    // eslint-disable-next-line
    this.caller.defaults.headers.common['Authorization'] = '';
  }
}

const api = Api.getInstance();

export default api;
