import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));

import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NoRole from '@app/pages/NoRole';
import SearchEmployee from '@app/pages/SearchEmployee/SearchEmployee';
import MainLayoutSearch from '../layouts/main/MainLayoutSearch/MainLayoutSearch';

import MainLayout from '../layouts/main/MainLayout/MainLayout';
import RequireAdmin from './RequireAdmin';
import Error404Page from '@app/pages/Error404Page';

const LoginPage = React.lazy(() => import('@app/pages/LoginPage'));
const ListEmployeePage = React.lazy(() => import('@app/pages/EmployeePages/ListEmployee/ListEmployee'));
const DetailEmployeePage = React.lazy(() => import('@app/pages/EmployeePages/DetailEmployee/DetailEmployee'));
const ListProjectPage = React.lazy(() => import('@app/pages/ProjectPages/ListProject/ListProject'));
const DetailEmployeeInBulPage = React.lazy(() => import('@app/pages/SearchEmployee/DetailEmployee'));
const DetailProjectPage = React.lazy(() => import('@app/pages/ProjectPages/DetailProject/DetailProject'));
const DashboardPage = React.lazy(() => import('@app/pages/DashboardPage/DashboardPage'));
const AdminPage = React.lazy(() => import('@app/pages/AdminPage/AdminPage'));
const CandidatesPage = React.lazy(() => import('@app/pages/CandidatesPage/ListCandidates/CandidatesPage'));
const DetailCandidatePage = React.lazy(
  () => import('@app/pages/CandidatesPage/DetailCandidatePage/DetailCandidatePage'),
);

const SearchEmployeeInProjectPage = React.lazy(
  () => import('@app/pages/SearchEmployee/SearchEmployeeInProject/SearchEmployeeInProject'),
);

const EmployeePlanInProjectPage = React.lazy(
  () => import('@app/pages/SearchEmployee/EmployeePlanInProject/EmployeePlanInProject'),
);

const Login = withLoading(LoginPage);
const ListEmployee = withLoading(ListEmployeePage);
const DetailEmployee = withLoading(DetailEmployeePage);
const ListProject = withLoading(ListProjectPage);
const DetailEmployeeInBul = withLoading(DetailEmployeeInBulPage);
const DetailProject = withLoading(DetailProjectPage);
const Dashboard = withLoading(DashboardPage);
const Candidates = withLoading(CandidatesPage);
const DetailCandidate = withLoading(DetailCandidatePage);
const ManagerUser = withLoading(AdminPage);
const SearchEmployeeInProject = withLoading(SearchEmployeeInProjectPage);
const EmployeePlanInProject = withLoading(EmployeePlanInProjectPage);

export const NFT_DASHBOARD_PATH = '/';
export const CANDIDATE_DEFAULT = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayoutSearch />
    </RequireAuth>
  );
  const protectedLayoutAdmin = (
    <RequireAdmin>
      <MainLayout />
    </RequireAdmin>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={CANDIDATE_DEFAULT} element={<AuthLayout />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Login />} />
        </Route>
        <Route path="employee" element={protectedLayout}>
          <Route index element={<SearchEmployee />} />
          <Route path=":id" element={<DetailEmployeeInBul />} />
        </Route>
        <Route path="project" element={protectedLayout}>
          <Route index element={<ListProject />} />
          <Route path=":id" element={<DetailProject />} />
          <Route path=":id/add-employee" element={<SearchEmployeeInProject />} />
          <Route path=":id/:id_employee_plan" element={<EmployeePlanInProject />} />
        </Route>
        <Route path="dashboard" element={protectedLayout}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="candidates" element={protectedLayout}>
          <Route index element={<Candidates />} />
          <Route path=":id" element={<DetailCandidate />} />
        </Route>
        <Route path="search" element={protectedLayout}>
          <Route index element={<ListEmployee />} />
          <Route path=":id" element={<DetailEmployee />} />
        </Route>
        <Route path="admin" element={protectedLayoutAdmin}>
          <Route index element={<ManagerUser />} />
        </Route>

        <Route path="no_role">
          <Route index element={<NoRole />} />
        </Route>
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </BrowserRouter>
  );
};
