import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ListEmployeeInBul from '@app/components/list-employee/List/ListEmployeeInBul';

const SearchEmployee = () => {
  const navigate = useNavigate();

  const dataToken = useSelector((state: any) => state.token);
  const checkRole = dataToken?.tokenValueInit?.roles;
  useEffect(() => {
    if (checkRole?.toString() === 'ADMIN') {
      navigate('/admin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkRole]);

  return (
    <div>
      <ListEmployeeInBul />
    </div>
  );
};

export default SearchEmployee;
