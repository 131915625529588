import styled from 'styled-components';
import Search from 'antd/lib/input/Search';
import { Button, Input, Modal, Row, Select, Table, TreeSelect } from 'antd';
import { Button as AntButton } from 'antd';
import { BaseCard as CommonCard } from '@app/components/common/BaseCard/BaseCard';

export const ControlContainer = styled.div`
  margin: 30px 0px;
  width: 100%;
  z-index: 10;
`;

export const SearchInput = styled(Search)`
  overflow: visible;
  border: 1px solid none;
  border-radius: 8px;
  border-color: 1px solid gray;

  button.ant-btn.ant-btn-default.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
    display: none;
  }
  .ant-input-affix-wrapper .ant-input-affix-wrapper-lg::after {
    /* border-color: white !important; */
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child),
  .ant-input-group-addon:first-child,
  .ant-input-group-addon:first-child .ant-select .ant-select-selector,
  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input,
  .ant-input-group > .ant-input:first-child,
  .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector {
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    /* border-color: white !important; */
  }
`;
export const CustomInput = styled(Input)`
  &.ant-input-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 14px;
    position: relative;
    border-radius: 8px;
  }
  .ant-input-prefix,
  .ant-input-suffix {
    display: flex;
    flex: none;
    align-items: center;

    position: absolute;
    right: 10px;
    z-index: 9;
    top: 10px;
  }
`;
export const CustomButton = styled(Button)`
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  font-weight: 500;
  background-color: #9254de;
  color: white;
  /* width: auto; */
`;
export const CustomTreeSelect = styled(TreeSelect)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid white;
    border-radius: 8px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &.ant-select.ant-tree-select.ant-select-lg.sc-iEXKAA.eUAtOs.ant-select-multiple.ant-select-allow-clear.ant-select-show-search {
    width: 100%;
  }
`;
export const ControlSpin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DivFilter = styled.div`
  margin: 10px 0px;
`;
export const DivControl = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
export const DivControlLeft = styled.div`
  flex-basis: 50%;
`;

export const CustomButtonImport = styled(Button)`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  font-weight: 500;
  background-color: white;
  color: #9254de;
  width: auto;
  margin-right: 10px;
`;
export const ControlNavigate = styled.div`
  display: flex;
  margin: 10px 0px;
`;
export const PostionBtn = styled.div`
  text-align: center !important;
  margin: 20px 0px;
`;
export const CustomDivFile = styled.div`
  margin: 10px 60px;
`;
export const ButtonCancel = styled(AntButton)`
  padding: 6px 15px 6px 15px;
  min-width: 114px;
  height: 40px;
  background: #fff;
  color: #9254de;
  border-color: #9254de;
  border-radius: 8px;
  align-items: center;
  letter-spacing: 1.25px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 10px;
  border: 1px solid #9254de;
  /* &:hover {
    color: #5440b8;
    border-color: #5440b8;
  }
  &:focus {
    color: #5440b8;
    border-color: #5440b8;
  } */
`;
export const ButtonSubmit = styled(AntButton)`
  padding: 5px 15px 5px 15px;
  min-width: 140px;
  height: 40px;
  background: #9254de;
  border-radius: 4px;
  align-items: center;
  letter-spacing: 1.25px;
  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  border-radius: 8px;
  line-height: 16px;
  color: #ffffff;
  margin: 0px;
  border: 1px solid #9254de;
  &:hover {
    color: #fff;
    border-color: #9254de;
    background: #9254de;
    opactity: 0.8;
  }
  &:focus {
    color: #fff;
    border-color: #9254de;
    background: #9254de;
    opactity: 0.8;
  }
`;
export const CustomTable = styled(Table)`
  & thead .ant-table-cell {
    color: white;
    background-color: #9254de !important;

    line-height: 1.25rem;

    & .anticon {
      color: var(--primary-color);
    }
  }
  .ant-table-tbody tr:nth-child(even) {
    background-color: #ffffff; /* Màu sắc tùy chỉnh cho hàng chẵn */
  }

  /* Định dạng cho hàng lẻ */
  .ant-table-tbody tr:nth-child(odd) {
    background-color: #f5f5f5; /* Màu sắc tùy chỉnh cho hàng lẻ */
  }
`;

export const CustomModal = styled(Modal)`
  button.ant-btn.ant-btn-primary {
    text-align: center;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    left: 45%;
    background-color: #9254de !important;
  }
`;

export const CustomTablePreview = styled(Table)`
  & thead .ant-table-cell {
    color: black;
    background-color: #efdbff !important;

    line-height: 1.25rem;

    & .anticon {
      color: var(--primary-color);
    }
  }
  .ant-table-tbody tr:nth-child(even) {
    background-color: #ffffff; /* Màu sắc tùy chỉnh cho hàng chẵn */
  }

  /* Định dạng cho hàng lẻ */
  .ant-table-tbody tr:nth-child(odd) {
    background-color: #f5f5f5; /* Màu sắc tùy chỉnh cho hàng lẻ */
  }
`;
export const CustomEffort3 = styled.div`
  width: 51px;
  height: 30px;
  border-radius: 100px;
  padding: 12px 0px;
  background-color: #d9f7be;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomEffort1 = styled.div`
  width: 51px;
  height: 30px;
  border-radius: 100px;
  padding: 12px 0px;
  background-color: #ffe7ba;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomEffort2 = styled.div`
  width: 51px;
  height: 30px;
  border-radius: 100px;
  padding: 12px 0px;
  background-color: #ffccc7;
  color: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CustomDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingCustom = styled.div`
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
`;
export const Card = styled(CommonCard)`
  width: 100%;
  margin-bottom: 1.25rem;
  .ant-card-head-title {
    font-size: 1rem;
  }

  .ant-card-body {
    display: flex;
    flex-wrap: wrap;

    padding: 0 !important;
    align-items: center;
  }
  .ant-card-body:before {
    display: none;
  }
  .ant-card-body:after {
    display: none;
  }
  .ant-tabs.ant-tabs-top {
    width: 100% !important;
  }
  .ant-card-head-title {
    font-size: 14px;
  }
  &.ant-card-bordered {
    border: 1px solid var(--border-color);
  }
`;

export const WrapControl = styled.div`
  margin: 20px;
`;
export const CustomSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8px;
  }
`;
