import { createSelector } from '@reduxjs/toolkit';
import { State } from './type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectSelf = (state: any) => state.employee;

export const selector = {
  selectEmployeeData: createSelector(selectSelf, (state) => state),

  selectList: createSelector(selectSelf, (state) => state.list),
  selectItem: createSelector(selectSelf, (state) => state.item),
  selectCVHistory: createSelector(selectSelf, (state) => state.cvHistory),
  selectProjectByEmployee: createSelector(selectSelf, (state) => state.projectByEmployee),
  selectProfessionProject: createSelector(selectSelf, (state) => state.ProfessionProject),
  selectListNote: createSelector(selectSelf, (state) => state.listNotes),
  selectInsertNote: createSelector(selectSelf, (state) => state.insertNotes),
  selectUpdateNote: createSelector(selectSelf, (state) => state.updateNotes),
  selectDeleteNote: createSelector(selectSelf, (state) => state.deleteNotes),
};
