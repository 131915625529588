import { createSlice } from '@reduxjs/toolkit';

import { State } from './type';
import { addUserList, getDetailUser, getUserList, removeUser, searchUser, updateUser } from './actions';

const initialState: State = {
  list: {
    loading: false,
    result: [],
  },
  item: {
    loading: false,
    result: [],
  },
  read: {
    loading: false,
    result: undefined,
  },
  update: {
    loading: false,
    result: undefined,
  },
  remove: {
    loading: false,
    result: undefined,
  },
};

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserList.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(getUserList.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });

    builder.addCase(addUserList.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });

    builder.addCase(addUserList.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });

    builder.addCase(addUserList.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });
    builder.addCase(getDetailUser.pending, (state) => {
      state.read.loading = true;
      state.read.result = initialState.read.result;
      state.read.error = {};
    });
    builder.addCase(getDetailUser.fulfilled, (state, action) => {
      state.read.loading = false;
      state.read.result = action.payload;
      state.read.error = {};
    });
    builder.addCase(getDetailUser.rejected, (state, action) => {
      state.read.loading = false;
      state.read.error = action.error;
      state.read.result = initialState.read.result;
    });
    builder.addCase(updateUser.pending, (state) => {
      state.update.loading = true;
      state.update.result = initialState.update.result;
      state.update.error = {};
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.update.loading = false;
      state.update.result = action.payload;
      state.update.error = {};
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.update.loading = false;
      state.update.error = action.error;
      state.update.result = initialState.update.result;
    });
    builder.addCase(removeUser.pending, (state) => {
      state.remove.loading = true;
      state.remove.result = initialState.remove.result;
      state.remove.error = {};
    });
    builder.addCase(removeUser.fulfilled, (state, action) => {
      state.remove.loading = false;
      state.remove.result = action.payload;
      state.remove.error = {};
    });
    builder.addCase(removeUser.rejected, (state, action) => {
      state.remove.loading = false;
      state.remove.error = action.error;
      state.remove.result = initialState.remove.result;
    });
    builder.addCase(searchUser.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(searchUser.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(searchUser.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  getUserList,
  addUserList,
  getDetailUser,
  updateUser,
  removeUser,
  searchUser,
};

export const { reducer } = slice;
