import styled from 'styled-components';
import { FONT_SIZE } from '@app/styles/themes/constants';
import { BaseMenu } from '@app/components/common/BaseMenu/BaseMenu';

export const Menu = styled(BaseMenu)`
  background: transparent;
  border-right: 0;
  font-weight: 400px;
  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.md};
    color: #9254de;
    font-size: 14px;
  }

  .ant-menu-item-icon {
    width: 1.25rem;
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  span[role='img'],
  a,
  .ant-menu-item,
  .ant-menu-submenu {
    color: #262626;
    /* font-weight: bold; */
    fill: var(--color-hover);
  }

  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    a,
    .ant-menu-item-icon,
    .ant-menu-title-content {
      font-size: 14px;
      font-weight: bold;
      fill: var(--color-hover);
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: #9254de;
      /* font-weight: bold; */

      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-arrow,
      span[role='img'] {
        color: var(--color-hover) !important;
        fill: var(--color-hover) !important;
      }
    }
  }

  .ant-menu-item-selected {
    background-color: var(--secondary-background-color) !important;
    /* height: auto;
    line-height: 1.5rem; */
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    .ant-menu-item-icon,
    a {
      /* color: #9254de; */
      fill: var(--color-hover) !important;
      font-weight: bold;
    }
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: var(--secondary-background-color) !important;
    color: #9254de !important;
    font-weight: bold;
  }

  .ant-menu.ant-menu-sub.ant-menu-inline {
    background-color: white;
  }
  .icon-active .ant-menu-item-icon {
    color: #9254de;
    fill: #9254de !important;
  }

  .ant-menu-title-content {
    white-space: normal;
    word-wrap: break-word;
    height: auto;
    line-height: 1.15rem;
    /* padding: 10px 0px; */
  }
`;
