/* eslint-disable no-var */

import ExcelJS from 'exceljs';
import * as S from './DowloadTemplate.styles';
import { dataOptionJobTitles, dataOptionLanguage } from '@app/constants/defaultOption';

const DowloadTemplate = () => {
  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Template');

    const hugeListSheet = workbook.addWorksheet('HugeList');
    hugeListSheet.addRows(dataOptionJobTitles.map((item: any) => [item]));
    const languageSheet = workbook.addWorksheet('Language');
    languageSheet.addRows(dataOptionLanguage?.map((item: any) => [item]));

    const resultArray = [
      [
        'Account',
        'FullName',
        'DOB',
        'JobTitle',
        'Skill',
        'Seniority',
        'Language',
        'Site',
        'Department',
        'ContractDate',
        'Hiring for Project name',
      ],
      ['', '', '', '', '', '', '', '', '', '', ''],
    ];

    worksheet.addRows(resultArray);

    for (let i = 2; i <= resultArray.length; i++) {
      worksheet.getCell(`G${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Language!A1:A500'],
      };
    }

    for (let i = 2; i <= resultArray.length; i++) {
      worksheet.getCell(`F${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['"Junior,Middle,Senior,Expert"'],
      };
    }
    for (let i = 2; i <= resultArray.length; i++) {
      worksheet.getCell(`H${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['"Ha Noi,Ho Chi Minh,Da Nang,Quy Nhon,Can Tho,Hue"'],
      };
    }
    for (let i = 2; i <= resultArray.length; i++) {
      worksheet.getCell(`D${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,

        formulae: ['HugeList!A1:A500'],
      };
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Template.xlsx';
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <S.CustomDivTemplate>
        <S.CustomButtonTemplate type="text" onClick={exportToExcel}>
          Download file template
        </S.CustomButtonTemplate>
      </S.CustomDivTemplate>
    </>
  );
};

export default DowloadTemplate;
