const dataOptionTranform = (arr: string[]) => {
  return arr.map((item: any) => ({
    text: item,
    value: item,
  }));
};

export const dataOptionJobTitles = [
  'AI Engineer',
  'AWS Consultant',
  'Accountant',
  'Administration Officer',
  'Application Security Engineer',
  'Artificial Intelligence Engineer',
  'Artificial Intelligence Scientist',
  'Asset Management Officer',
  'Assistant',
  'Automation Tester',
  'Back-end',
  'Bridge Software Engineer',
  'Business Analyst',
  'Business Development Manager',
  'Business Development Officer',
  'Business Process Outsourcing Business Officer',
  'Business Process Outsourcing Project Manager',
  'Cloud Security Engineer',
  'Compaided Project Manager',
  'Computer Aided Design Engineer',
  'Computer Aided Engineering Engineer',
  'Comtor',
  'Comtor Manager',
  'Customer Care Officer',
  'Customer Relationship Manager',
  'Customer Relationship Officer',
  'DS Operator',
  'DS Reviewer',
  'DS Teamlead',
  'Data Analyst',
  'Data Engineer',
  'Data Scientist',
  'Designer',
  'DevOps Engineer',
  'Developer',
  'Digital Automotive Platform Operator',
  'Digital Automotive Platform Teamlead',
  'Employee Relations Officer',
  'Enterprise Resource Planning Business Analyst',
  'Enterprise Resource Planning Business Supporter',
  'Enterprise Resource Planning Implementer',
  'Enterprise Resource Planning Technical Supporter',
  'External Relation Officer',
  'Financial Analysis Manager',
  'Financial Analysis Officer',
  'Financial Control Officer',
  'Front Soft Project Manager',
  'Front-end',
  'Fullstack Quality Engineer',
  'General Affair Manager',
  'Helpdesk Officer',
  'Human Resource Generalist',
  'Information Security Management System Officer',
  'Information Technology Head',
  'Internal Communication Officer',
  'Leader',
  'Legislation Officer',
  'Marketing Officer',
  'Network Administrator',
  'Offshore Business Delivery Manager',
  'Operating Systems and Middleware Developer',
  'Presale Officer',
  'Quality Accountant Officer',
  'Quality Assurance Head',
  'Quality Assurance Project Manager',
  'Quality Document Officer',
  'Recruitment Officer',
  'Resource Assurance Manager',
  'Sale accountant',
  'Scrum Master',
  'Service Configuration Officer',
  'Service Helpdesk Officer',
  'Service Management Officer',
  'Service Technical Support Officer',
  'Software Development Head',
  'Software Development Manager',
  'Software Product Owner',
  'Software Project Manager',
  'Software Project Quality Officer',
  'Solution Architect',
  'Systems Applications Products Functional Consultant',
  'Systems Applications Products Technical Consultant',
  'Tester',
  'Training Development Officer',
  'Youth Unions Officer',
];
export const dataOptionSkill = [
  'Comtor',
  'HMI Application',
  'Hardware Engineering',
  'Low-Code',
  'SAP Functional Consulting',
  'React Native',
  'CAE Engineering',
  'Security',
  'Angular',
  'iOS',
  'BPO',
  'Xamarin',
  'C/C++',
  'null',
  'AUTOSAR',
  'ERP Consulting',
  'BC',
  'VueJS',
  'Product Design',
  'UI/UX',
  'ERP Development',
  'AWS Cloud',
  'SA',
  'ReactJS',
  'DE',
  'ERP Business Support',
  'Data Analytics',
  '3D',
  'Graphic Design',
  'ERP Implementation',
  'PO',
  'AI Scientist',
  'Embedded BSP/OS',
  'AI',
  'Quality Assurance',
  'AUTOSAR Software',
  'Motion Graphics',
  'Scrum Master',
  'ERP Application Consulting',
  'IT Service',
  'ERP Technical Support',
  'Front PM',
  'Manual Test',
  'CAD Engineering',
  'Google Cloud',
  'BA',
  'Fullstack QE/QC',
  'Flutter',
  'Mobile Apps',
  'Automation Test',
  'PHP',
  'Embedded Software',
  'BrSE',
  'Front SE',
  'HW/Embedded',
  '.NET',
  'Python',
  'HTML/CSS',
  'DS',
  'Robotic Process Automation (RPA)',
  'Android',
  'Project Management',
  'Azure Cloud',
  'Database',
  'NodeJs',
  'Tester Manager',
  'Java',
  'Ruby & Golang & Cobol',
  'Offshore/OB DM',
  'SAP Technical Consulting',
  'Back Office',
  'DevOps',
];
export const dataOptionLanguage = [
  'Akan',
  'Amharic',
  'Arabic',
  'Assamese',
  'Awadhi',
  'Azerbaijani',
  'Balochi',
  'Belarusian',
  'Bengali',
  'Bhojpuri',
  'Burmese',
  'Cantonese',
  'Cebuano',
  'Chewa',
  'Chhattisgarhi',
  'Chinese',
  'Chittagonian',
  'Czech',
  'Deccan',
  'Dhundhari',
  'Dutch',
  'English',
  'French',
  'Fula',
  'Gan',
  'German',
  'Greek',
  'Gujarati',
  'Haitian Creole',
  'Hakka',
  'Haryanvi',
  'Hausa',
  'Hiligaynon',
  'Hindi',
  'Hmong',
  'Hungarian',
  'Igbo',
  'Ilokano',
  'Italian',
  'Japanese',
  'Javanese',
  'Jin',
  'Kannada',
  'Kazakh',
  'Khmer',
  'Kinyarwanda',
  'Kirundi',
  'Konkani',
  'Korean',
  'Kurdish',
  'Madurese',
  'Magahi',
  'Maithili',
  'Malagasy',
  'Malay/Indonesian',
  'Malayalam',
  'Mandarin',
  'Marathi',
  'Marwari',
  'Min Bei',
  'Min Dong',
  'Min Nan',
  'Mossi',
  'Nepali',
  'Odia',
  'Oromo',
  'Pashto',
  'Persian',
  'Polish',
  'Portuguese',
  'Punjabi',
  'Quechua',
  'Romanian',
  'Russian',
  'Saraiki',
  'Serbo-Croatian',
  'Shona',
  'Sindhi',
  'Sinhalese',
  'Somali',
  'Spanish',
  'Sundanese',
  'Swahili',
  'Swedish',
  'Sylheti',
  'Tagalog',
  'Tamil',
  'Telugu',
  'Thai',
  'Turkish',
  'Ukrainian',
  'Urdu',
  'Uyghur',
  'Uzbek',
  'Vietnamese',
  'Wu',
  'Xhosa',
  'Xiang',
  'Yoruba',
  'Zhuang',
  'Zulu',
];
export const dataOptionSite = ['Ha Noi', 'Da Nang', 'Ho Chi Minh', 'Quy Nhon', 'Can Tho', 'Hue', 'Philippines'];

export const dataOptionSeniority = ['Junior', 'Middle', 'Senior', 'Expert'];

export const dataJobTitlesTranform = dataOptionTranform(dataOptionJobTitles);
export const dataSkillsTranform = dataOptionTranform(dataOptionSkill);
export const dataSeniorityTranform = dataOptionTranform(dataOptionSeniority);
export const dataLanguagesTranform = dataOptionTranform(dataOptionLanguage);
export const dataSitesTranform = dataOptionTranform(dataOptionSite);
