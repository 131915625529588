import { Link, useLocation } from 'react-router-dom';
import * as S from './Breakcumb.styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { Skeleton } from 'antd';
export interface IBreadCrumb {
  path: string;
  replaceName: string;
}

const BreadCrumbs = () => {
  const { pathname } = useLocation();
  const storedBreadCrumbs = useSelector((state: RootState) => state.breadcumbs.breadcrumbsValue);
  const arrayRoute = pathname.slice(1).split('/');
  const converData = storedBreadCrumbs ? storedBreadCrumbs : [];
  const changeBreadCrumbs = arrayRoute.map((item) => {
    const findNameReplace = converData.find((x: IBreadCrumb) => x.path === item)?.replaceName;
    return findNameReplace ? { parth: item, name: findNameReplace } : { parth: item, name: item };
  });
  return (
    <S.BreakcumbWrap>
      {changeBreadCrumbs.map((item, i) => {
        const previousParts = changeBreadCrumbs.slice(0, i);
        const previousPartsMap = previousParts.map((item) => item.parth);
        return (
          <div key={item.parth}>
            {i !== 0 && <S.BreakcumbSlash>/</S.BreakcumbSlash>}

            <Link
              to={`${
                previousPartsMap?.length > 0 ? `/${previousPartsMap?.join('/')}/${item.parth}` : `/${item.parth}`
              }`}
            >
              {item.name}
            </Link>
          </div>
        );
      })}
    </S.BreakcumbWrap>
  );
};

export default BreadCrumbs;
