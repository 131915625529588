import IconBriefcase from './Briefcase.svg';
import IconLogoImage from './icon_logoimage.svg';
import IconLogoText from './icon_logotext.svg';
import IconGithub from './icon_github.svg';
import IconUser from './User.svg';
import IconBook from './Book.svg';
import IconVector from './Vector.svg';
import IconLine1 from './line1.svg';
import IconLine2 from './line2.svg';
import IconLine3 from './line3.svg';
import IconLine4 from './line4.svg';
import IconLine5 from './line5.svg';
import IconCreateProject from './icon_create_project.svg';
import IconUserImage from './UserAdd.svg';
import IconProject from './icon_project.svg';
import IconEmployee from './icon-employee.svg';
import IconResource from './icon-resource.svg';
import IconDashboard from './icon-dashboard.svg';
import IconSearch from './icon-search.svg';
import IconCheck from './CheckCircle.svg';
import IconBack from './icon-back.svg';
import IconDelEmployee from './icon_del.svg';
import IconPending from './icon-pending.svg';
import IconAddPendding from './icon-addpenddinglist.svg';
import IconSearchEmployee from './icon_searchemployee.svg';
import IconSkill from './icon-skill.svg';
import IconDomain from './icon-domain.svg';
import IconLanguage from './icon-language.svg';
import IconCertificate from './icon-certificate.svg';
import IconClient from './icon-client.svg';
import IconEducation from './icon-edu.svg';
import IconMore from './icon_more.svg';
import IconEdit from './icon-edit.svg';
import IconDelete from './DeleteOutlined.svg';
import IconImport from './icon-importfile.svg';
import IconExport from './icon-exportfile.svg';
import IconAdd from './icon-add.svg';
import IconEditDetail from './icon_edit_detail.svg';
import IconError from './error_icon.svg';
import IconAddEmployee from './Add.svg';
import IconPlanResource from './icon_plan_resource.svg';
import IconUploadImage from './icon_upload.svg';
import IconCoppy from './icon_coppy.svg';

export const BriefcaseSVG = () => {
  return <img src={IconBriefcase} />;
};
export const IconLogoImageSVG = () => {
  return <img src={IconLogoImage} />;
};
export const IconLogoTextSVG = () => {
  return <img src={IconLogoText} />;
};
export const IconGithubSVG = () => {
  return <img src={IconGithub} style={{ display: 'inline-block', marginLeft: 5, marginRight: 5 }} />;
};
export const IconUsersSVG = () => {
  return <img src={IconUser} style={{ display: 'inline-block', marginRight: 10, color: '#000000' }} />;
};
export const IconBookSVG = () => {
  return <img src={IconBook} style={{ display: 'inline-block', marginRight: 10, color: '#000000' }} />;
};
export const IconVectorSVG = () => {
  return <img src={IconVector} style={{ display: 'inline-block', marginRight: 10, color: '#000000' }} />;
};

export const IconLine1SVG = ({ onClick }: any) => {
  return <img src={IconLine1} style={{ fill: '#9254DE', cursor: 'pointer' }} onClick={onClick} />;
};
export const IconLine2SVG = ({ onClick }: any) => {
  return <img src={IconLine2} style={{ fill: '#9254DE', cursor: 'pointer' }} onClick={onClick} />;
};
export const IconLine3SVG = ({ onClick }: any) => {
  return <img src={IconLine3} style={{ fill: '#9254DE', cursor: 'pointer' }} onClick={onClick} />;
};
export const IconLine4SVG = ({ onClick }: any) => {
  return <img src={IconLine4} style={{ fill: '#9254DE', cursor: 'pointer' }} onClick={onClick} />;
};
export const IconLine5SVG = ({ onClick }: any) => {
  return <img src={IconLine5} style={{ fill: '#9254DE', cursor: 'pointer' }} onClick={onClick} />;
};

export const IconCreateProjectSVG = () => {
  return <img src={IconCreateProject} style={{ display: 'inline-block', marginRight: 10 }} />;
};

export const IconUserSVG = () => {
  return <img src={IconUserImage} />;
};

export const ProjectIcon = () => {
  return <img src={IconProject} />;
};

export const EmployeeIcon = () => {
  return <img src={IconEmployee} />;
};

export const ResourceIcon = () => {
  return <img src={IconResource} />;
};
export const DashboardIcon = () => {
  return <img src={IconDashboard} />;
};

export const SearchIcon = () => {
  return <img src={IconSearch} />;
};
export const BackIcon = () => {
  return <img src={IconBack} style={{ marginRight: 10 }} />;
};

export const IconPendingSVG = () => {
  return <img src={IconPending} style={{ display: 'inline-block', marginRight: 10, color: '#000000' }} />;
};

export const IconAddPenddingSVG = () => {
  return <img src={IconAddPendding} style={{ display: 'inline-block', marginRight: 10 }} />;
};

export const IconSearchEmployeeSVG = () => {
  return <img src={IconSearchEmployee} />;
};

export const IconKillSVG = () => {
  return <img src={IconSkill} style={{ display: 'inline-block', marginRight: 10 }} />;
};
export const IconDomainSVG = () => {
  return <img src={IconDomain} style={{ display: 'inline-block', marginRight: 10 }} />;
};

export const IconLanguageSVG = () => {
  return <img src={IconLanguage} style={{ display: 'inline-block', marginRight: 10 }} />;
};
export const IconCertificateSVG = () => {
  return <img src={IconCertificate} style={{ display: 'inline-block', marginRight: 10 }} />;
};
export const IconClientSVG = () => {
  return <img src={IconClient} style={{ display: 'inline-block', marginRight: 10 }} />;
};
export const IconEducationSVG = () => {
  return <img src={IconEducation} style={{ display: 'inline-block', marginRight: 10 }} />;
};

export const IconDelEmployeeSVG = () => {
  return <img src={IconDelEmployee} style={{ display: 'inline-block', marginRight: 10 }} />;
};

export const CheckIconSVG = () => {
  return (
    <img src={IconCheck} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }} />
  );
};

export const IconMoreSVG = () => {
  return <img src={IconMore} />;
};

export const IconEditSVG = () => {
  return <img src={IconEdit} />;
};

export const IconDeleteSVG = () => {
  return <img src={IconDelete} />;
};
export const IconImportFileSVG = () => {
  return <img src={IconImport} style={{ display: 'inline-block', marginRight: 10 }} />;
};
export const IconExportFileSVG = () => {
  return <img src={IconExport} style={{ display: 'inline-block', marginRight: 10 }} />;
};
export const IconAddSVG = () => {
  return <img src={IconAdd} />;
};
export const IconEditDetailSVG = () => {
  return <img src={IconEditDetail} style={{ display: 'inline-block', marginRight: 10 }} />;
};

export const IconErrorSVG = () => {
  return <img src={IconError} style={{ display: 'inline-block', marginRight: 10 }} />;
};

export const IconAddEmployeeSVG = () => {
  return <img src={IconAddEmployee} style={{ display: 'inline-block', marginRight: 10 }} />;
};
export const IconPlanResourceSVG = () => {
  return <img src={IconPlanResource} style={{ display: 'inline-block', marginRight: 10 }} />;
};
export const IconUploadSVG = () => {
  return <img src={IconUploadImage} />;
};
export const IconCoppySVG = () => {
  return <img src={IconCoppy} />;
};
