import React from 'react';

import { useResponsive } from '@app/hooks/useResponsive';
import { DesktopHeaderSearch } from './layouts/DesktopHeaderSearch';
import { MobileHeaderSearch } from './layouts/MobileHeaderSearch';

interface HeaderProps {
  toggleSider: () => void;
  isSiderOpened: boolean;
  isTwoColumnsLayout: boolean;
}

export const HeaderSearch: React.FC<HeaderProps> = ({ toggleSider, isSiderOpened, isTwoColumnsLayout }) => {
  const { isTablet } = useResponsive();

  return isTablet ? (
    <DesktopHeaderSearch isTwoColumnsLayout={isTwoColumnsLayout} />
  ) : (
    <MobileHeaderSearch toggleSider={toggleSider} isSiderOpened={isSiderOpened} />
  );
};
