import { createSlice } from '@reduxjs/toolkit';

import { State } from './type';
import {
  addEmployeeToProject,
  addProjectByBul,
  delEmployeeToProject,
  delResource,
  detailEmployeeToProject,
  getEmployeeInProject,
  getListAllProjectCode,
  getProjectByBul,
  getProjectByBulSearch,
  getProjectByName,
  updateEmployeeToProject,
  updateProject,
} from './actions';

const initialState: State = {
  list: {
    loading: false,
    result: [],
  },
  listName: {
    loading: false,
    result: [],
  },
  item: {
    loading: false,
    result: [],
  },
  itemProject: {
    loading: false,
    result: [],
  },
  read: {
    loading: false,
    result: undefined,
  },
  update: {
    loading: false,
    result: undefined,
  },
  remove: {
    loading: false,
    result: undefined,
  },
  listProject: {
    loading: false,
    result: [],
  },
  removeProject: {
    loading: false,
    result: undefined,
  },
  updateProject: {
    loading: false,
    result: undefined,
  },
  detailProject: {
    loading: false,
    result: undefined,
  },
  delete: {
    loading: false,
    result: undefined,
  },
  listProjectCode: {
    loading: false,
    result: undefined,
  },
};

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectByBul.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(getProjectByBul.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(getProjectByBul.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });

    builder.addCase(addProjectByBul.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });

    builder.addCase(addProjectByBul.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });

    builder.addCase(addProjectByBul.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });

    builder.addCase(getProjectByName.pending, (state) => {
      state.listName.loading = true;
      state.listName.result = initialState.listName.result;
      state.listName.error = {};
    });

    builder.addCase(getProjectByName.fulfilled, (state, action) => {
      state.listName.loading = false;
      state.listName.result = action.payload;
      state.listName.error = {};
    });
    builder.addCase(getProjectByName.rejected, (state, action) => {
      state.listName.loading = false;
      state.listName.error = action.error;
      state.listName.result = initialState.listName.result;
    });

    builder.addCase(getProjectByBulSearch.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(getProjectByBulSearch.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(getProjectByBulSearch.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });

    builder.addCase(getEmployeeInProject.pending, (state) => {
      state.listProject.loading = true;
      state.listProject.result = initialState.listProject.result;
      state.listProject.error = {};
    });
    builder.addCase(getEmployeeInProject.fulfilled, (state, action) => {
      state.listProject.loading = false;
      state.listProject.result = action.payload;
      state.listProject.error = {};
    });
    builder.addCase(getEmployeeInProject.rejected, (state, action) => {
      state.listProject.loading = false;
      state.listProject.error = action.error;
      state.listProject.result = initialState.listProject.result;
    });

    builder.addCase(addEmployeeToProject.pending, (state) => {
      state.itemProject.loading = true;
      state.itemProject.result = initialState.itemProject.result;
      state.itemProject.error = {};
    });
    builder.addCase(addEmployeeToProject.fulfilled, (state, action) => {
      state.itemProject.loading = false;
      state.itemProject.result = action.payload;
      state.itemProject.error = {};
    });
    builder.addCase(addEmployeeToProject.rejected, (state, action) => {
      state.itemProject.loading = false;
      state.itemProject.error = action.error;
      state.itemProject.result = initialState.itemProject.result;
    });

    builder.addCase(delEmployeeToProject.pending, (state) => {
      state.removeProject.loading = true;
      state.removeProject.result = initialState.removeProject.result;
      state.removeProject.error = {};
    });
    builder.addCase(delEmployeeToProject.fulfilled, (state, action) => {
      state.removeProject.loading = false;
      state.removeProject.result = action.payload;
      state.removeProject.error = {};
    });
    builder.addCase(delEmployeeToProject.rejected, (state, action) => {
      state.removeProject.loading = false;
      state.removeProject.error = action.error;
      state.removeProject.result = initialState.removeProject.result;
    });

    builder.addCase(updateEmployeeToProject.pending, (state) => {
      state.updateProject.loading = true;
      state.updateProject.result = initialState.updateProject.result;
      state.updateProject.error = {};
    });
    builder.addCase(updateEmployeeToProject.fulfilled, (state, action) => {
      state.updateProject.loading = false;
      state.updateProject.result = action.payload;
      state.updateProject.error = {};
    });
    builder.addCase(updateEmployeeToProject.rejected, (state, action) => {
      state.updateProject.loading = false;
      state.updateProject.error = action.error;
      state.updateProject.result = initialState.updateProject.result;
    });

    builder.addCase(detailEmployeeToProject.pending, (state) => {
      state.detailProject.loading = true;
      state.detailProject.result = initialState.detailProject.result;
      state.detailProject.error = {};
    });
    builder.addCase(detailEmployeeToProject.fulfilled, (state, action) => {
      state.detailProject.loading = false;
      state.detailProject.result = action.payload;
      state.detailProject.error = {};
    });
    builder.addCase(detailEmployeeToProject.rejected, (state, action) => {
      state.detailProject.loading = false;
      state.detailProject.error = action.error;
      state.detailProject.result = initialState.detailProject.result;
    });

    builder.addCase(delResource.pending, (state) => {
      state.delete.loading = true;
      state.delete.result = initialState.delete.result;
      state.delete.error = {};
    });
    builder.addCase(delResource.fulfilled, (state, action) => {
      state.delete.loading = false;
      state.delete.result = action.payload;
      state.delete.error = {};
    });
    builder.addCase(delResource.rejected, (state, action) => {
      state.delete.loading = false;
      state.delete.error = action.error;
      state.delete.result = initialState.delete.result;
    });

    builder.addCase(updateProject.pending, (state) => {
      state.update.loading = true;
      state.update.result = initialState.update.result;
      state.update.error = {};
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.update.loading = false;
      state.update.result = action.payload;
      state.update.error = {};
    });
    builder.addCase(updateProject.rejected, (state, action) => {
      state.update.loading = false;
      state.update.error = action.error;
      state.update.result = initialState.update.result;
    });
    builder.addCase(getListAllProjectCode.pending, (state) => {
      state.listProjectCode.loading = true;
      state.listProjectCode.result = initialState.listProjectCode.result;
      state.listProjectCode.error = {};
    });
    builder.addCase(getListAllProjectCode.fulfilled, (state, action) => {
      state.listProjectCode.loading = false;
      state.listProjectCode.result = action.payload;
      state.listProjectCode.error = {};
    });
    builder.addCase(getListAllProjectCode.rejected, (state, action) => {
      state.listProjectCode.loading = false;
      state.listProjectCode.error = action.error;
      state.listProjectCode.result = initialState.listProjectCode.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  getProjectByBul,
  addProjectByBul,
  getProjectByName,
  getProjectByBulSearch,
  addEmployeeToProject,
  delEmployeeToProject,
  updateEmployeeToProject,
  getEmployeeInProject,
  detailEmployeeToProject,
  updateProject,
  delResource,
  getListAllProjectCode,
};

export const { reducer } = slice;
