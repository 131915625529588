import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import './assets/style/style.css';
import GlobalStyle from './styles/GlobalStyle';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';

import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import { msalConfig } from './config/auth';
import axios from 'axios';
import { APP_VERSION } from './version';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);
  ConfigProvider.config({ theme: { primaryColor: '#9254DE' } });
  const msalInstance = new PublicClientApplication(msalConfig);

  const data = {
    version: 'v3.1.16',
  };
  console.log('version', data.version);

  const [apiVersion, setApiVersion] = useState<any>();

  const endpointRequest = `${process.env.REACT_APP_API_URL}/version`;

  const checkAppVersion = async () => {
    if (apiVersion && apiVersion !== APP_VERSION) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }

      localStorage.clear();
      localStorage.clear();
      window.location.reload();
    }
  };

  useEffect(() => {
    checkAppVersion();
  }, []);

  useEffect(() => {
    const getRequest = async () => {
      try {
        const response = await axios({
          method: 'POST',
          url: endpointRequest,
          data: data,
        });
        setApiVersion(response?.headers);
      } catch (error) {
        console.log(error);
      }
    };

    getRequest();
  }, []);

  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <MsalProvider instance={msalInstance}>
        <HelmetProvider>
          <ConfigProvider locale={language === 'en' ? enUS : deDe}>
            <AppRouter />
          </ConfigProvider>
        </HelmetProvider>
      </MsalProvider>
    </>
  );
};

export default App;
