import api from '@app/api';
import { IEmployeeParams, IGetAllEmployeeParams } from '@app/types/employee';
import { buildUrl } from './app';

export const getTitleListService = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/title_list`;
  const response = await api.caller.get(endpoint);
  return response;
};
export const insertEmployeeService = async (item: IEmployeeParams) => {
  const endpointCreate = `${process.env.REACT_APP_API_URL}/modules/employee/insert`;
  const response = await api.caller.post(endpointCreate, item);
  return response;
};
export const autoFillServiceEmployee = async (formData: any, signal?: AbortSignal) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/modules/employee/autofill`;
  const response = await api.caller.post(endpoint, formData, { signal });
  return response;
};
export const getListEmployeeService = async (payload: any) => {
  // const endpoint = `${process.env.REACT_APP_API_URL}/uat/employee`;
  const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/modules/employee`, payload.params);
  const response = await api.caller.get(endpoint);
  return response;
};

export const updateEmployeeService = async (item: any, id: string, payload: any) => {
  const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/modules/employee/${id}/update`, payload.params);
  const response = await api.caller.put(endpoint, item);
  return response;
};
export const deleteEmployeeService = async (id: string) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/modules/employee/${id}`;
  const response = await api.caller.delete(endpoint);
  return response;
};
export const listAllDepartmentService = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/department/list_all`;
  const response = await api.caller.get(endpoint);
  return response;
};
export const getListProjectService = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/modules/project`;
  const response = await api.caller.get(endpoint);
  return response;
};

export const getEmployeeDetail = async (id: string) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/modules/employee/${id}`;
  const response = await api.caller.get(endpoint);
  return response;
};
export const importFileEmployeePreview = async (payload: any, formData: any) => {
  const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/modules/employee_data/upload`, payload);
  const response = await api.caller.post(endpoint, formData);
  return response;
};
export const importFileEmployee = async (payload: any) => {
  const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/modules/employee_data/upload`, payload);
  const response = await api.caller.post(endpoint);
  return response;
};

export const getAllEmployee = async (param: IGetAllEmployeeParams) => {
  const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/modules/employee`, param);
  const response = await api.caller.get(endpoint);
  return response;
};
