import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { WithChildrenProps } from '@app/types/generalTypes';
import { useSelector } from 'react-redux';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const dataToken = useSelector((state: any) => state.token);
  const token = dataToken?.tokenValueInit?.roles;

  // const token = localStorage.getItem('Candidate');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [navigate, token]);

  if (token?.includes('ADMIN')) {
    return <>{children}</>;
  } else if (token?.toString() === 'BUL') {
    return <>{children}</>;
  } else {
    return <Navigate to="/" replace />;
  }
  // return token ? <>{children}</> : <Navigate to="/" replace />;
};

export default RequireAuth;
