/* eslint-disable @typescript-eslint/no-empty-function */
import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import React, { createContext, useEffect, useState } from 'react';
import * as S from './component/ListInEmployee/ListInEmployee.styles';
import ListInEmployee from './component/ListInEmployee/ListInEmployee';
import DashboardEmployee from './component/Dashboard/DashboardEmployee';
import { IOptionJobTitle } from '@app/types/common';

import { listAllDepartmentService } from '@app/services/employee.service';
import { EStatusEmployee } from '@app/types/employee';
import { useDispatch } from 'react-redux';
import { setStatus } from '@app/store/status/statusSlice';
import { dataJobTitlesTranform } from '@app/constants/defaultOption';

interface createContextType {
  getTitleList: IOptionJobTitle[];
  getListAllDepartment: [];
}

export const EmployeeContext = createContext<createContextType>({
  getTitleList: [],
  getListAllDepartment: [],
});

const ListEmployeeInBul = () => {
  const [dataListDepartment, setDataListDepartment] = useState<any>();
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState('1');

  const listAllDepartments = async () => {
    try {
      const response = await listAllDepartmentService();
      setDataListDepartment(response?.data);
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    listAllDepartments();
  }, []);
  const onChangeTab = (key: string) => {
    setActiveKey(key);
  };
  useEffect(() => {
    if (activeKey === '1') {
      dispatch(setStatus(true));
    }
    if (activeKey === '2') {
      dispatch(setStatus(false));
    }
  }, [activeKey]);
  return (
    <EmployeeContext.Provider
      value={{
        getTitleList: dataJobTitlesTranform ? dataJobTitlesTranform : [],
        getListAllDepartment: dataListDepartment ? dataListDepartment : [],
      }}
    >
      <S.Card className="styled_card">
        <BaseTabs
          defaultActiveKey="1"
          onChange={onChangeTab}
          destroyInactiveTabPane={true}
          items={[
            {
              key: '1',
              label: <span>Employee list</span>,
              children: <ListInEmployee status={EStatusEmployee.ACTIVE}></ListInEmployee>,
            },
            {
              key: '2',
              label: <span>Inactive</span>,
              children: <ListInEmployee status={EStatusEmployee.INACTIVE}></ListInEmployee>,
            },
            {
              key: '3',
              label: <span>Summary</span>,
              children: <DashboardEmployee></DashboardEmployee>,
            },
          ]}
        />
      </S.Card>
    </EmployeeContext.Provider>
  );
};

export default ListEmployeeInBul;
