import { createSlice } from '@reduxjs/toolkit';
import {
  getListEmployee,
  getOneEmployee,
  getCVHistory,
  getProjectByEmployee,
  deleteCV,
  getAccountProfessionProject,
  deleteNote,
  updateNote,
  insertNote,
  getListNotes,
} from './actions';
import { State } from './type';

const initialState: State = {
  list: {
    loading: false,
    result: [],
  },
  item: {
    loading: false,
    result: [],
  },
  cvHistory: {
    loading: false,
    result: [],
  },
  projectByEmployee: {
    loading: false,
    result: [],
  },
  ProfessionProject: {
    loading: false,
    result: [],
  },
  updateNotes: {
    loading: false,
    result: [],
  },
  listNotes: {
    loading: false,
    result: [],
  },
  insertNotes: {
    loading: false,
    result: [],
  },
  deleteNotes: {
    loading: false,
    result: [],
  },
};

export const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    resetList(state) {
      state.list.result = [];
      state.list.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListEmployee.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(getListEmployee.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(getListEmployee.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });

    builder.addCase(getOneEmployee.pending, (state) => {
      state.item.loading = true;
      state.item.result = undefined;
      state.item.error = {};
    });

    builder.addCase(getOneEmployee.fulfilled, (state, action) => {
      state.item.loading = false;
      state.item.result = action.payload;
      state.item.error = {};
    });

    builder.addCase(getOneEmployee.rejected, (state, action) => {
      state.item.loading = false;
      state.item.error = action.error;
      state.item.result = undefined;
    });

    builder.addCase(getCVHistory.pending, (state) => {
      state.cvHistory.loading = true;
      state.cvHistory.result = initialState.cvHistory.result;
      state.cvHistory.error = {};
    });
    builder.addCase(getCVHistory.fulfilled, (state, action) => {
      state.cvHistory.loading = false;
      state.cvHistory.result = action.payload;
      state.cvHistory.error = {};
    });
    builder.addCase(getCVHistory.rejected, (state, action) => {
      state.cvHistory.loading = false;
      state.cvHistory.error = action.error;
      state.cvHistory.result = initialState.cvHistory.result;
    });

    builder.addCase(getProjectByEmployee.pending, (state) => {
      state.projectByEmployee.loading = true;
      state.projectByEmployee.result = initialState.projectByEmployee.result;
      state.projectByEmployee.error = {};
    });
    builder.addCase(getProjectByEmployee.fulfilled, (state, action) => {
      state.projectByEmployee.loading = false;
      state.projectByEmployee.result = action.payload;
      state.projectByEmployee.error = {};
    });
    builder.addCase(getProjectByEmployee.rejected, (state, action) => {
      state.projectByEmployee.loading = false;
      state.projectByEmployee.error = action.error;
      state.projectByEmployee.result = initialState.projectByEmployee.result;
    });

    builder.addCase(deleteCV.pending, (state) => {
      state.list.loading = true;
      state.list.result = undefined;
      state.list.error = {};
    });
    builder.addCase(deleteCV.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(deleteCV.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = undefined;
    });
    builder.addCase(getAccountProfessionProject.pending, (state) => {
      state.ProfessionProject.loading = true;
      state.ProfessionProject.result = initialState.ProfessionProject.result;
      state.ProfessionProject.error = {};
    });
    builder.addCase(getAccountProfessionProject.fulfilled, (state, action) => {
      state.ProfessionProject.loading = false;
      state.ProfessionProject.result = action.payload;
      state.ProfessionProject.error = {};
    });
    builder.addCase(getAccountProfessionProject.rejected, (state, action) => {
      state.ProfessionProject.loading = false;
      state.ProfessionProject.error = action.error;
      state.ProfessionProject.result = initialState.ProfessionProject.result;
    });

    builder.addCase(getListNotes.pending, (state) => {
      state.listNotes.loading = true;
      state.listNotes.result = initialState.listNotes.result;
      state.listNotes.error = {};
    });
    builder.addCase(getListNotes.fulfilled, (state, action) => {
      state.listNotes.loading = false;
      state.listNotes.result = action.payload;
      state.listNotes.error = {};
    });
    builder.addCase(getListNotes.rejected, (state, action) => {
      state.listNotes.loading = false;
      state.listNotes.error = action.error;
      state.listNotes.result = initialState.listNotes.result;
    });
    builder.addCase(insertNote.pending, (state) => {
      state.insertNotes.loading = true;
      state.insertNotes.result = initialState.insertNotes.result;
      state.insertNotes.error = {};
    });
    builder.addCase(insertNote.fulfilled, (state, action) => {
      state.insertNotes.loading = false;
      state.insertNotes.result = action.payload;
      state.insertNotes.error = {};
    });
    builder.addCase(insertNote.rejected, (state, action) => {
      state.insertNotes.loading = false;
      state.insertNotes.error = action.error;
      state.insertNotes.result = initialState.insertNotes.result;
    });

    builder.addCase(updateNote.pending, (state) => {
      state.updateNotes.loading = true;
      state.updateNotes.result = initialState.updateNotes.result;
      state.updateNotes.error = {};
    });
    builder.addCase(updateNote.fulfilled, (state, action) => {
      state.updateNotes.loading = false;
      state.updateNotes.result = action.payload;
      state.updateNotes.error = {};
    });
    builder.addCase(updateNote.rejected, (state, action) => {
      state.updateNotes.loading = false;
      state.updateNotes.error = action.error;
      state.updateNotes.result = initialState.updateNotes.result;
    });
    builder.addCase(deleteNote.pending, (state) => {
      state.deleteNotes.loading = true;
      state.deleteNotes.result = initialState.deleteNotes.result;
      state.deleteNotes.error = {};
    });
    builder.addCase(deleteNote.fulfilled, (state, action) => {
      state.deleteNotes.loading = false;
      state.deleteNotes.result = action.payload;
      state.deleteNotes.error = {};
    });
    builder.addCase(deleteNote.rejected, (state, action) => {
      state.deleteNotes.loading = false;
      state.deleteNotes.error = action.error;
      state.deleteNotes.result = initialState.deleteNotes.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  getListEmployee,
  getOneEmployee,
  getCVHistory,
  getProjectByEmployee,
  deleteCV,
  getAccountProfessionProject,
  deleteNote,
  updateNote,
  insertNote,
  getListNotes,
};
export const { resetList } = slice.actions;
export const { reducer } = slice;
