/* eslint-disable no-var */

import ExcelJS from 'exceljs';
import * as S from './ExportExel.styles';
import { IconExportFileSVG } from '@app/assets/svg';
import { EmployeeExport } from '@app/types/employee';
import { dataOptionJobTitles, dataOptionLanguage } from '@app/constants/defaultOption';
interface EmployeeExportProps {
  dataExport: EmployeeExport[];
}
const ExportToExcel = ({ dataExport }: any) => {
  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Employee Template');

    const hugeListSheet = workbook.addWorksheet('HugeList');
    hugeListSheet.addRows(dataOptionJobTitles.map((item: any) => [item]));
    const languageSheet = workbook.addWorksheet('Language');
    languageSheet.addRows(dataOptionLanguage?.map((item: any) => [item]));

    const resultArray = [];

    const headerRow = [];
    for (var key in dataExport?.[0]) {
      headerRow.push(key);
    }
    resultArray.push(headerRow);

    for (var i = 0; i < dataExport?.length; i++) {
      var dataRow = [];
      for (var key in dataExport[i]) {
        dataRow.push(dataExport[i][key] as any);
      }
      resultArray.push(dataRow);
    }

    worksheet.addRows(resultArray);

    for (let i = 2; i <= resultArray.length; i++) {
      worksheet.getCell(`G${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['Language!A1:A500'],
      };
    }

    for (let i = 2; i <= resultArray.length; i++) {
      worksheet.getCell(`F${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['"Junior,Middle,Senior,Expert"'],
      };
    }
    for (let i = 2; i <= resultArray.length; i++) {
      worksheet.getCell(`H${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['"Ha Noi,Ho Chi Minh,Da Nang,Quy Nhon,Can Tho,Hue"'],
      };
    }
    for (let i = 2; i <= resultArray.length; i++) {
      worksheet.getCell(`D${i}`).dataValidation = {
        type: 'list',
        allowBlank: true,

        formulae: ['HugeList!A1:A500'],
      };
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Employee_Template.xlsx';
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <S.CustomButtonImport size="large" onClick={exportToExcel} icon={<IconExportFileSVG />}>
        Export file
      </S.CustomButtonImport>
    </>
  );
};

export default ExportToExcel;
