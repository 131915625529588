import { DownOutlined } from '@ant-design/icons';
import { BREAKPOINTS } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { HeaderActionWrapper } from '../../../Header.styles';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and (min-width: ${BREAKPOINTS.md}px) {
    border-radius: 50px;
    padding: 0.3125rem 0.2rem;
  }
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-secondary-color);

  @media only screen and (min-width: ${BREAKPOINTS.md}px) {
    color: var(--text-main-color);
  }
`;
export const BrandSpanFooter = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #262626;
  font-weight: 500;
`;
export const CustomP = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  color: #8c8c8c;
  margin-bottom: 20px;
`;
