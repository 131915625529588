export const dataListMonth = [
  {
    value: 'January',
    label: 'January',
  },
  {
    value: 'February',
    label: 'February',
  },
  {
    value: 'March',
    label: 'March',
  },
  {
    value: 'April',
    label: 'April',
  },
  {
    value: 'May',
    label: 'May',
  },
  {
    value: 'June',
    label: 'June',
  },
  {
    value: 'July',
    label: 'July',
  },
  {
    value: 'August',
    label: 'August',
  },
  {
    value: 'September',
    label: 'September',
  },
  {
    value: 'October',
    label: 'October',
  },

  {
    value: 'November',
    label: 'November',
  },
  {
    value: 'December',
    label: 'December',
  },
];

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const dataExpamle = [
  {
    account: 'exam0',
    full_name: 'Nguyen Van A',
    dob: 1,
    job_title: 'Accountant',
    skill: 'reactjs',
    seniority: 'Middle',
    language: 'Amharic',
    site: 'Ho Chi Minh',
    department: 'AIC',
    contract_date: '2023/02/02',
    hiring_for_project_name: 'Demo1',
    index: 0,
  },
  {
    account: 'exam1',
    full_name: 'Nguyen Van B',
    dob: 1,
    job_title: 'Accountant',
    skill: 'reactjs',
    seniority: 'Middle',
    language: 'Amharic',
    site: 'Ho Chi Minh',
    department: 'AIC',
    contract_date: '2023/02/02',
    hiring_for_project_name: 'Demo',
    index: 1,
  },
];
