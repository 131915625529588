import { createSelector } from '@reduxjs/toolkit';
import { State } from './type';

const selectSelf = (state: any) => state.admin;

export const selector = {
  selectList: createSelector(selectSelf, (state) => state.list),
  selectItem: createSelector(selectSelf, (state) => state.item),
  selectRead: createSelector(selectSelf, (state) => state.read),
  selectUpdate: createSelector(selectSelf, (state) => state.update),
  selectRemove: createSelector(selectSelf, (state) => state.remove),
};
