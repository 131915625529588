import { createSlice } from '@reduxjs/toolkit';

export interface IBreadCrumb {
  path: string;
  replaceName: string;
}
export interface IIniteState {
  breadcrumbsValue: IBreadCrumb[];
}

const initialState: IIniteState = {
  breadcrumbsValue: [],
};

const breadcumbsSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setBreadCumbs(state, action) {
      state.breadcrumbsValue = action.payload;
    },
  },
});

export const { setBreadCumbs } = breadcumbsSlice.actions;

export default breadcumbsSlice.reducer;
