import { createAsyncThunk } from '@reduxjs/toolkit';

import { ActionParams } from '../types/common';
import api from '@app/api';
import { createAsyncAction } from '@app/services/thunkWrapper';
import { buildUrl } from '@app/services/app';

export const getUserList = createAsyncThunk(
  'list/user_list',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/users`;

    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);

export const addUserList = createAsyncThunk(
  'add/user_list',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/add_role`;

    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;
    return data;
  }),
);

export const getDetailUser = createAsyncThunk(
  'detail/detail_list',

  createAsyncAction(async (payload: ActionParams<any, any>) => {
    const endpoint = buildUrl(`${process.env.REACT_APP_API_URL}/user_detail`, payload.params);
    const response = await api.caller.get(endpoint);
    const data = response.data;
    return data;
  }),
);

export const updateUser = createAsyncThunk(
  'update/update_user',
  createAsyncAction(async (payload: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/update_role`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const removeUser = createAsyncThunk(
  'remove/remove_user',
  createAsyncAction(async (payload: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/remove_users`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);

export const searchUser = createAsyncThunk(
  'search/search_users',
  createAsyncAction(async (payload: any) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/search_users`;
    const response = await api.caller.post(endpoint, payload.params);
    const data = response.data;

    return data;
  }),
);
