import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Error } from '@app/components/Error/Error';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import error404 from '@app/assets/images/error404.svg';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const NoRole: React.FC = () => {
  const { t } = useTranslation();

  const dataToken = useSelector((state: any) => state.token);

  const checkRole = dataToken?.tokenValueInit?.roles;

  const navigation = useNavigate();

  useEffect(() => {
    if (checkRole?.toString() === 'ADMIN') {
      navigation('/admin');
    }
  }, [checkRole]);

  return (
    <>
      <PageTitle>{t('common.clientError')}</PageTitle>
      <Error img={error404} msg={'You do not have permission to access this site'} />
    </>
  );
};

export default NoRole;
