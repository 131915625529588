// eslint-disable-next-line @typescript-eslint/no-explicit-any
// export const buildUrl = (url: string, parameters: any): string => {
//   let qs = '';
//   for (const key in parameters) {
//     const value = parameters[key];
//     if (value !== '') {
//       qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
//     }
//   }
//   if (qs.length > 0) {
//     qs = qs.substring(0, qs.length - 1);
//     url = url + '?' + qs;
//   }
//   return url;
// };
export const buildUrl = (url: string, parameters: any): string => {
  let qs = '';
  let isFirstParameter = true;

  for (const key in parameters) {
    const value = parameters[key];
    if (value !== '') {
      if (isFirstParameter) {
        qs += '?';
        isFirstParameter = false;
      } else {
        qs += '&';
      }
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }
  }

  return url + qs;
};

export const generateUniqueId = () => {
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  return uuid;
};
