import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { WithChildrenProps } from '@app/types/generalTypes';
import { useSelector } from 'react-redux';

const RequireAdmin: React.FC<WithChildrenProps> = ({ children }) => {
  const dataToken = useSelector((state: any) => state.token);
  const token = dataToken?.tokenValueInit?.roles;

  const navigate = useNavigate();

  useEffect(() => {
    if (!token?.includes('ADMIN')) {
      navigate('/login');
    }
  }, [token]);

  return token?.includes('ADMIN') ? <>{children}</> : <Navigate to="/" replace />;
};

export default RequireAdmin;
