import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';
import { BREAKPOINTS } from '@app/styles/themes/constants';

import styled, { css } from 'styled-components';

interface Header {
  $isTwoColumnsLayoutHeader: boolean;
}

export const Header = styled(BaseLayout.Header)<Header>`
  line-height: 1.5;

  @media only screen and (min-width: ${BREAKPOINTS.md}px) {
    ${(props) =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`;
