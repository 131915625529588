/* eslint-disable @typescript-eslint/no-empty-function */
import { AutoComplete, Button, Col, DatePicker, Form, Row, Spin, TreeSelect, Typography, Upload, message } from 'antd';
import * as S from './ModelInsert.styles';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useSelector } from 'react-redux';
import { employeeInRoleSelector } from '@app/store';
import moment from 'moment';
import { ReactElement, useContext, useEffect, useState } from 'react';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
// eslint-disable-next-line @typescript-eslint/ban-types
import { UploadChangeParam, UploadFile, UploadProps, RcFile } from 'antd/lib/upload';
import TextArea from 'antd/lib/input/TextArea';
import { EmployeeContext } from '../../ListEmployeeInBul';
import { IEmployeeParams } from '@app/types/employee';
import {
  autoFillServiceEmployee,
  getAllEmployee,
  getListProjectService,
  insertEmployeeService,
  listAllDepartmentService,
  updateEmployeeService,
} from '@app/services/employee.service';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import {
  dataLanguagesTranform,
  dataSeniorityTranform,
  dataSitesTranform,
  dataSkillsTranform,
} from '@app/constants/defaultOption';

// eslint-disable-next-line @typescript-eslint/ban-types
type FormType = {};
const ModelInsert = ({
  isModalOpen,
  onCloseDialog,
  type,
  data,
  onDone,
}: {
  type: 'Edit' | 'Add';
  isModalOpen: boolean;
  data?: any;
  onCloseDialog: () => void;
  onDone: () => void;
}): ReactElement<any, any> => {
  const [form] = Form.useForm<FormType>();
  const [valueDepartment, setvalueDepartment] = useState<any>();
  const { RangePicker } = DatePicker;
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const dataLogin = useSelector((state: any) => state.token);
  const [isLoadingUpload, setIsLoadingUpload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fileUpload, setFileUpload] = useState<any>(null);
  const defaultDepartment = dataLogin?.tokenValueInit?.all_bu_list?.[0];
  const dataAutoFill = useSelector(employeeInRoleSelector.selectListCVUpload);
  const { getTitleList, getListAllDepartment } = useContext(EmployeeContext);
  const dateFormat = 'YYYY/MM/DD';
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const [dataAllEmployee, setDataAllEployee] = useState<{ value: string }[]>([]);
  const [dataListProjectName, setDataListProjectName] = useState<any>();
  const [dataUpload, setDataUpload] = useState<any>();
  const initialValues = { department: defaultDepartment };

  const updatedTreeData = getListAllDepartment?.map((o: any) => {
    if (o?.children?.length > 0) {
      return {
        ...o,
        disabled: true,
      };
    } else {
      return {
        ...o,
      };
    }
  });
  const handleOk = async () => {
    try {
      await form.validateFields();
      onCloseDialog();
      form.submit();
    } catch (error) {}
  };

  const resetForm = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    onCloseDialog();
    resetForm();
    setFileUpload(null);
    setDataUpload(null);
    setIsLoadingUpload(false);
  };

  const onChangeDepartment = (newValue: any) => {
    setvalueDepartment(newValue);
  };

  const handleOnRemoveFile = (): void => {
    setFileUpload(null);
    setErrorMsg(null);
    form.setFieldValue('file', null);
  };
  const handlerFileChange = async (file: UploadChangeParam<UploadFile>) => {
    const fileUpload = file.file;
    setFileUpload(fileUpload);
    const data = new FormData();
    if (fileUpload) {
      data.append('file', fileUpload as RcFile);
      try {
        setIsLoadingUpload(true);
        const dataFill = await autoFillServiceEmployee(data);
        const dataResponse = dataFill.data.message;

        setDataUpload(dataResponse);
        setIsLoadingUpload(false);
        if (dataResponse) {
          const educations = dataResponse?.educations?.map((item: any) => {
            return { school_name: item, field_of_study: '' };
          });

          const certificates = dataResponse?.certificates?.map((item: any) => {
            return { certificate: item };
          });

          const honors_awards = dataResponse?.honors_awards?.map((item: any) => {
            return { award: item };
          });

          const languages = dataResponse?.languages?.map((item: any) => {
            return { language: item, level: '' };
          });

          form.setFieldsValue({
            full_name: dataResponse?.name,
            site: dataResponse?.site,
            list_cv: dataResponse?.list_cv,
            description: dataResponse?.description,
            educations: educations,
            certificates: certificates,
            honors_awards: honors_awards,
            languages: languages,
            experiences: undefined, //todo
          });
        }
      } catch (error: any) {
        console.log(error);

        message.error(
          error?.response && error?.response?.data?.detail ? error.response.data.detail : 'Something went wrong',
        );
        setFileUpload(undefined);
        setDataUpload(undefined);
        setIsLoadingUpload(false);
      }
    }
  };

  const onRemoveFile = (file: UploadFile) => {
    setFileUpload(undefined);
    setDataUpload(undefined);
  };
  /**
   * upload file props
   * @interface UploadProps
   */
  const propsUpload: UploadProps = {
    onChange: handlerFileChange,
    onRemove: onRemoveFile,
    multiple: false,
    maxCount: 1,
    beforeUpload: (file: File) => {
      if (!(file.type === 'application/pdf')) {
        setErrorMsg(`The file is not in the correct format`);
      } else if (file.size > 5 * 1024 * 1024) {
        setErrorMsg(`File size invalid. CV file maximum size is 5MB`);
      } else {
        setErrorMsg(null);
      }
      return false;
    },
    showUploadList: false,
    defaultFileList: [],
  };

  const onFinish = async (inputData: IEmployeeParams) => {
    delete inputData?.upload_file;

    const dataTranform = {
      ...inputData,
      ...(!!inputData.dob && { dob: moment(inputData.dob).format('YYYY/MM/DD') }),
      ...(!!inputData.contract_date && { contract_date: moment(inputData.contract_date).format('YYYY/MM/DD') }),
      _id: dataUpload ? dataUpload?._id : undefined,
      status: 'Active',
    };
    const dataTranformUpdate = {
      ...inputData,
      ...(!!inputData.dob && { dob: moment(inputData.dob).format('YYYY/MM/DD') }),
      ...(!!inputData.contract_date && { contract_date: moment(inputData.contract_date).format('YYYY/MM/DD') }),
      _id: data?._id ? data?._id : undefined,
    };

    const dataUpdate = new FormData();
    dataUpdate.append('request_data', JSON.stringify(dataTranformUpdate));

    const isValidForm = await form.validateFields();
    if (!errorMsg && isValidForm) {
      try {
        setIsLoading(true);
        if (data) {
          await updateEmployeeService(dataUpdate, data._id, {});
          onDone && onDone();
          resetForm();
          setIsLoading(false);
          onCloseDialog();
          setDataUpload(undefined);
          setFileUpload(undefined);
          message.success('Update employee success');
        } else {
          await insertEmployeeService(dataTranform);
          resetForm();
          onCloseDialog();
          onDone && onDone();
          setIsLoading(false);
          setDataUpload(undefined);
          setFileUpload(undefined);

          message.success('Add employee success');
        }
      } catch (error: any) {
        setIsLoading(false);

        message.error(error?.response?.data?.detail ? error?.response?.data?.detail : 'Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (type === 'Edit' && data) {
      const dataExperiencesFormat = data?.experiences?.map((item: any) => {
        return {
          ...item,
          duration: item.duration.length === 2 ? [moment(item.duration[0]), moment(item.duration[1])] : undefined,
        };
      });

      form.setFieldsValue({
        account: data?.account,
        full_name: data?.full_name,
        project_name: data?.project_name,
        site: data?.site,
        list_cv: data?.list_cv,
        picInterview: data?.interview_info?.picInterview,
        dob: data?.dob ? moment(data?.dob) : undefined,
        contract_date: data?.contract_date ? moment(data?.contract_date) : undefined,
        description: data?.description,
        educations: data?.educations,
        certificates: data?.certificates,
        honors_awards: data?.honors_awards,
        languages: data?.languages,
        supervisor: data?.supervisor?.account,
        experiences: dataExperiencesFormat, //todo
      });
    }
  }, [data]);

  const listAllProjectName = async () => {
    try {
      const response = await getListProjectService();

      const dataOptionMap = response?.data?.map((item: any) => ({
        title: item.project_name,
        value: item.project_name,
      }));
      setDataListProjectName(dataOptionMap);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getPanelValue = (searchText: string) =>
    !searchText ? [] : dataAllEmployee.filter((item) => item.value.includes(searchText));

  const onGetAllEmployee = async () => {
    try {
      const dataAllEmployee = await getAllEmployee({
        get_all_account: true,
      });
      const dataAllEmployeeTranfer = dataAllEmployee?.data?.map((item: string) => {
        return {
          value: item,
        };
      });
      setDataAllEployee(dataAllEmployeeTranfer);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onGetAllEmployee();
    listAllProjectName();
  }, []);

  return (
    <>
      {' '}
      <BaseModal
        destroyOnClose
        okText={type === 'Edit' ? 'Update record' : 'Add record'}
        cancelButtonProps={{
          style: { background: '#FFFFFF', borderColor: '#9254DE', color: '#9254DE', width: 109, borderRadius: 4 },
          disabled: isLoading,
        }}
        okButtonProps={{
          style: { background: '#9254DE', borderColor: '#9254DE', color: '#FFFFFF', width: 109, borderRadius: 4 },
          disabled: isLoading,
        }}
        maskClosable={false}
        centered
        width={828}
        title={type === 'Edit' ? 'Update employee' : 'Add employee'}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ overflowY: 'auto', position: 'relative', height: 'calc(100vh - 150px)', padding: '10px 16px' }}
      >
        {isLoading ||
          (isLoadingUpload && (
            <S.LoadingWrap>
              <BaseSpin size="large" />
            </S.LoadingWrap>
          ))}
        <BaseForm form={form} layout="vertical" name="userForm" onFinish={onFinish} initialValues={initialValues}>
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ display: 'flex', flexDirection: 'column' }}>
              <BaseForm.Item
                name="upload_file"
                label="Upload file CV"
                // rules={[{ required: true, message: 'Please Upload CV file' }]}
                style={{ marginBottom: 0 }}
              >
                <>
                  <Upload {...propsUpload} accept=".pdf" fileList={[]}>
                    <Button icon={<UploadOutlined />} style={{ marginBottom: 20 }}>
                      Click to Upload
                    </Button>
                  </Upload>
                  {fileUpload?.name && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <PaperClipOutlined />
                        <div>{fileUpload?.name}</div>
                      </div>
                      <DeleteOutlined
                        style={{ cursor: 'pointer', marginLeft: '8px', color: '#FF7875' }}
                        onClick={handleOnRemoveFile}
                      />
                    </div>
                  )}
                </>
              </BaseForm.Item>
            </Col>
          </Row>
          {dataAutoFill?.loading ? (
            <Spin></Spin>
          ) : (
            <>
              <Row gutter={8}>
                <Col span={24}>
                  <BaseForm.Item name="project_name" label="Hiring for Project name">
                    <S.CustomSelect
                      showSearch
                      size="large"
                      style={{ borderRadius: 4 }}
                      placeholder="Select a Hiring for Project name"
                      options={dataListProjectName}
                    />
                  </BaseForm.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={8}>
                  <BaseForm.Item
                    name="account"
                    label="Account"
                    rules={[
                      { required: true, message: 'Please input your Email' },
                      {
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject('Email is required');
                          }

                          if (value.includes('@') && !value.endsWith('@fpt.com')) {
                            return Promise.reject('Email must end with "@fpt.com"');
                          }

                          const validEmailPattern = /^(?:[a-zA-Z0-9._-]+(?:@fpt\.com)?|)$/;

                          if (validEmailPattern.test(value)) {
                            return Promise.resolve();
                          }

                          return Promise.reject('Invalid email format');
                        },
                      },
                    ]}
                  >
                    <S.CustomInput style={{ borderRadius: 4 }} size="large" placeholder="Account" />
                  </BaseForm.Item>
                </Col>
                <Col span={8}>
                  <BaseForm.Item
                    name="full_name"
                    label="Full name"
                    rules={[{ required: true, message: 'Please input your Full name' }]}
                  >
                    <S.CustomInput style={{ borderRadius: 4 }} size="large" placeholder="Full name" />
                  </BaseForm.Item>
                </Col>
                <Col span={8}>
                  <BaseForm.Item
                    name="dob"
                    label="DOB"
                    // rules={[{ required: true, message: 'Please input your Job Title' }]}
                  >
                    <DatePicker size="large" style={{ width: '100%' }} format={dateFormat} />
                  </BaseForm.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <BaseForm.Item name="description" label="Professional summary">
                    <TextArea rows={4} autoSize={false} placeholder="Professional summary" />
                  </BaseForm.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <BaseForm.Item
                    name="department"
                    label="Department"
                    // rules={[
                    //   { required: true, message: 'Please select a Department' },
                    //   // { validator: validateSelectedDepartments },
                    // ]}
                  >
                    <S.CustomTreeSelect
                      size="large"
                      value={valueDepartment}
                      dropdownStyle={{ width: 400, overflow: 'auto' }}
                      treeData={updatedTreeData}
                      placeholder="Department"
                      treeDefaultExpandAll
                      onChange={onChangeDepartment}
                      showSearch
                      allowClear
                      treeCheckable={false}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    />
                  </BaseForm.Item>
                </Col>
                <Col span={12}>
                  <BaseForm.Item name="site" label="Site">
                    <S.CustomSelect
                      showSearch
                      size="large"
                      style={{ borderRadius: 4 }}
                      placeholder="Select a Site"
                      options={dataSitesTranform}
                      getPopupContainer={(triggerNode) => triggerNode}
                    />
                  </BaseForm.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <BaseForm.Item name="supervisor" label="Supervisor">
                    <AutoComplete
                      options={options}
                      // style={{ width: 200 }}
                      // onSelect={onSelect}
                      onSearch={(text) => setOptions(getPanelValue(text))}
                      placeholder="Supervisor"
                      allowClear
                      size="large"
                    />
                  </BaseForm.Item>
                </Col>
                <Col span={12}>
                  <BaseForm.Item name="contract_date" label="Contract date">
                    <DatePicker size="large" style={{ width: '100%' }} format={dateFormat} />
                  </BaseForm.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <div style={{ marginBottom: '8px' }}>Educational background</div>
                  <Form.List name="educations">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <S.FormListStyle key={key}>
                            <S.FormItemStyle>
                              <BaseForm.Item
                                {...restField}
                                name={[name, 'school_name']}
                                rules={[{ required: true, message: 'Missing School Name' }]}
                              >
                                <S.CustomInput
                                  style={{ borderRadius: 4, width: '100%' }}
                                  size="large"
                                  placeholder="School Name"
                                />
                              </BaseForm.Item>
                            </S.FormItemStyle>
                            <S.FormItemStyle>
                              <BaseForm.Item
                                {...restField}
                                name={[name, 'field_of_study']}
                                rules={[{ required: true, message: 'Missing Field Of Study ' }]}
                              >
                                <S.CustomInput
                                  style={{ borderRadius: 4, width: '100%' }}
                                  size="large"
                                  placeholder="Field Of Study"
                                />
                              </BaseForm.Item>
                              <Button
                                onClick={() => remove(name)}
                                style={{ padding: '4px', width: '40px', borderRadius: '4px', marginLeft: '8px' }}
                                size="large"
                              >
                                <DeleteOutlined style={{ color: '#FF7875' }} />
                              </Button>
                            </S.FormItemStyle>
                          </S.FormListStyle>
                        ))}
                        <BaseForm.Item>
                          <Button
                            type="primary"
                            ghost
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                            style={{ width: '24px', height: '24px' }}
                          />
                        </BaseForm.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <div style={{ marginBottom: '8px' }}>Languages</div>
                  <Form.List name="languages">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <S.FormListStyle key={key}>
                            <S.FormItemStyle>
                              <BaseForm.Item
                                {...restField}
                                name={[name, 'language']}
                                rules={[{ required: true, message: 'Missing Language' }]}
                              >
                                <S.CustomSelect
                                  showSearch
                                  size="large"
                                  style={{ borderRadius: 4 }}
                                  placeholder="Select a Language"
                                  options={dataLanguagesTranform}
                                  getPopupContainer={(triggerNode) => triggerNode}
                                />
                              </BaseForm.Item>
                            </S.FormItemStyle>
                            <S.FormItemStyle>
                              <BaseForm.Item
                                {...restField}
                                name={[name, 'level']}
                                rules={[{ required: true, message: 'Missing Level ' }]}
                              >
                                <S.CustomInput
                                  style={{ borderRadius: 4, width: '100%' }}
                                  size="large"
                                  placeholder="Level"
                                />
                              </BaseForm.Item>
                              <Button
                                onClick={() => remove(name)}
                                style={{ padding: '4px', width: '40px', borderRadius: '4px', marginLeft: '8px' }}
                                size="large"
                              >
                                <DeleteOutlined style={{ color: '#FF7875' }} />
                              </Button>
                            </S.FormItemStyle>
                          </S.FormListStyle>
                        ))}
                        <BaseForm.Item>
                          <Button
                            type="primary"
                            ghost
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                            style={{ width: '24px', height: '24px' }}
                          />
                        </BaseForm.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <div style={{ marginBottom: '8px' }}>Certificates</div>
                  <Form.List name="certificates">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <S.FormListStyle key={key}>
                            <S.FormItemStyle>
                              <BaseForm.Item
                                {...restField}
                                name={[name, 'certificate']}
                                rules={[{ required: true, message: 'Missing Certificate' }]}
                              >
                                <S.CustomInput
                                  style={{ borderRadius: 4, width: '100%' }}
                                  size="large"
                                  placeholder="Certificate name"
                                />
                              </BaseForm.Item>
                              <Button
                                onClick={() => remove(name)}
                                style={{ padding: '4px', width: '40px', borderRadius: '4px', marginLeft: '8px' }}
                                size="large"
                              >
                                <DeleteOutlined style={{ color: '#FF7875' }} />
                              </Button>
                            </S.FormItemStyle>
                          </S.FormListStyle>
                        ))}
                        <BaseForm.Item>
                          <Button
                            type="primary"
                            ghost
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                            style={{ width: '24px', height: '24px' }}
                          />
                        </BaseForm.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <div style={{ marginBottom: '8px' }}>Awards</div>
                  <Form.List name="honors_awards">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <S.FormListStyle key={key}>
                            <S.FormItemStyle>
                              <BaseForm.Item
                                {...restField}
                                name={[name, 'award']}
                                rules={[{ required: true, message: 'Missing Award' }]}
                              >
                                <S.CustomInput
                                  style={{ borderRadius: 4, width: '100%' }}
                                  size="large"
                                  placeholder="Award name"
                                />
                              </BaseForm.Item>
                              <Button
                                onClick={() => remove(name)}
                                style={{ padding: '4px', width: '40px', borderRadius: '4px', marginLeft: '8px' }}
                                size="large"
                              >
                                <DeleteOutlined style={{ color: '#FF7875' }} />
                              </Button>
                            </S.FormItemStyle>
                          </S.FormListStyle>
                        ))}
                        <BaseForm.Item>
                          <Button
                            type="primary"
                            ghost
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                            style={{ width: '24px', height: '24px' }}
                          />
                        </BaseForm.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              <Row gutter={8} style={{ marginBottom: '24px' }}>
                <Col span={24}>
                  <div style={{ marginBottom: '8px' }}>Skills</div>
                  {/* <EditTable /> */}

                  <Form.List name="list_cv">
                    {(fields, { add, remove }) => (
                      <>
                        <S.TableFormStyle>
                          <div className="table-container">
                            <div className="table-row heading">
                              <div className="row-item">Job Tilte</div>
                              <div className="row-item">skills</div>
                              <div className="row-item">Seniority</div>
                              <div className="row-item row-item--action">Action</div>
                            </div>
                            {fields.map(({ key, name, ...restField }) => {
                              return (
                                <div className="table-row-group" key={key}>
                                  <div className="table-row">
                                    <div className="row-item">
                                      <BaseForm.Item
                                        {...restField}
                                        name={[name, 'job_title']}
                                        rules={[{ required: true, message: 'Missing Job title' }]}
                                        style={{ width: '100%', marginBottom: 0 }}
                                      >
                                        <S.CustomSelect
                                          showSearch
                                          size="large"
                                          style={{ borderRadius: 4 }}
                                          placeholder={`Select Job title`}
                                          options={getTitleList}
                                          getPopupContainer={(triggerNode) => triggerNode}
                                        />
                                      </BaseForm.Item>
                                    </div>
                                    <div className="row-item">
                                      <BaseForm.Item
                                        {...restField}
                                        name={[name, 'skills']}
                                        rules={[{ required: true, message: 'Missing skills' }]}
                                        style={{ width: '100%', marginBottom: 0 }}
                                      >
                                        <S.CustomSelect
                                          mode="multiple"
                                          size={'large'}
                                          placeholder="Select Skill"
                                          style={{ width: '100%' }}
                                          options={dataSkillsTranform}
                                          maxTagTextLength={20}
                                          getPopupContainer={(triggerNode) => triggerNode}
                                        />
                                      </BaseForm.Item>
                                    </div>
                                    <div className="row-item">
                                      <BaseForm.Item
                                        {...restField}
                                        name={[name, 'seniority']}
                                        rules={[{ required: true, message: 'Missing seniority' }]}
                                        style={{ width: '100%', marginBottom: 0 }}
                                      >
                                        <S.CustomSelect
                                          options={dataSeniorityTranform}
                                          placeholder={`Select Seniority`}
                                          size="large"
                                          // defaultValue={seniority[0].value}
                                          style={{ borderRadius: 4 }}
                                          // disabled={!isEditing(key)}
                                          getPopupContainer={(triggerNode) => triggerNode}
                                        />
                                      </BaseForm.Item>
                                    </div>
                                    <div className="row-item row-item--action">
                                      <Typography.Link onClick={() => remove(name)}>
                                        <DeleteOutlined style={{ color: '#FF7875', padding: '10px' }} />
                                      </Typography.Link>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </S.TableFormStyle>
                        <BaseForm.Item>
                          <Button
                            type="primary"
                            ghost
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                            style={{ width: '24px', height: '24px' }}
                          />
                        </BaseForm.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={24}>
                  <div style={{ marginBottom: '8px' }}>Professional experience</div>
                  <Form.List name="experiences">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <S.FormListWrapStyle key={key}>
                            <CloseCircleOutlined
                              onClick={() => remove(name)}
                              style={{
                                position: 'absolute',
                                top: '-10px',
                                right: '-10px',
                                color: '#FF7875',
                                fontSize: '20px',
                                backgroundColor: '#ffffff',
                              }}
                            />
                            <S.FormListStyle>
                              <S.FormItemStyle className="layoutColumn">
                                <div style={{ marginBottom: '8px' }}>Company name</div>
                                <BaseForm.Item
                                  {...restField}
                                  name={[name, 'companyName']}
                                  rules={[{ required: true, message: 'Missing Company name' }]}
                                >
                                  <S.CustomInput
                                    style={{ borderRadius: 4, width: '100%' }}
                                    size="large"
                                    placeholder="Company name"
                                  />
                                </BaseForm.Item>
                              </S.FormItemStyle>
                              <S.FormItemStyle className="layoutColumn">
                                <div style={{ marginBottom: '8px' }}>Project name</div>
                                <BaseForm.Item
                                  {...restField}
                                  name={[name, 'projectName']}
                                  rules={[{ required: true, message: 'Missing Project name' }]}
                                >
                                  <S.CustomInput
                                    style={{ borderRadius: 4, width: '100%' }}
                                    size="large"
                                    placeholder="Project name"
                                  />
                                </BaseForm.Item>
                              </S.FormItemStyle>
                            </S.FormListStyle>
                            <S.FormListStyle>
                              <S.FormItemStyle className="layoutColumn">
                                <div style={{ marginBottom: '8px' }}>Duration</div>
                                <BaseForm.Item
                                  {...restField}
                                  name={[name, 'duration']}
                                  rules={[{ required: true, message: 'Missing Duration' }]}
                                >
                                  {/* <S.CustomInput
                                style={{ borderRadius: 4, width: '100%' }}
                                size="large"
                                placeholder="Duration"
                              /> */}
                                  <RangePicker
                                    style={{ borderRadius: 4, width: '100%' }}
                                    size="large"
                                    format={'YYYY/MM/DD'}
                                    placeholder={['From', 'To']}
                                    getPopupContainer={(triggerNode) => triggerNode}
                                  />
                                </BaseForm.Item>
                              </S.FormItemStyle>
                              <S.FormItemStyle className="layoutColumn">
                                <div style={{ marginBottom: '8px' }}>Team size</div>
                                <BaseForm.Item
                                  {...restField}
                                  name={[name, 'teamSize']}
                                  rules={[{ required: true, message: 'Missing Team size' }]}
                                >
                                  <S.CustomInput
                                    style={{ borderRadius: 4, width: '100%' }}
                                    size="large"
                                    placeholder="Team size"
                                  />
                                </BaseForm.Item>
                              </S.FormItemStyle>
                            </S.FormListStyle>
                            <S.FormListStyle>
                              <S.FormItemStyle className="layoutColumn">
                                <div style={{ marginBottom: '8px' }}>Position</div>
                                <BaseForm.Item
                                  {...restField}
                                  name={[name, 'position']}
                                  rules={[{ required: true, message: 'Missing Position' }]}
                                >
                                  <S.CustomInput
                                    style={{ borderRadius: 4, width: '100%' }}
                                    size="large"
                                    placeholder="Position"
                                  />
                                </BaseForm.Item>
                              </S.FormItemStyle>
                              <S.FormItemStyle className="layoutColumn">
                                <div style={{ marginBottom: '8px' }}>Responsibility</div>
                                <BaseForm.Item
                                  {...restField}
                                  name={[name, 'responsibility']}
                                  rules={[{ required: true, message: 'Missing Responsibility' }]}
                                >
                                  <S.CustomInput
                                    style={{ borderRadius: 4, width: '100%' }}
                                    size="large"
                                    placeholder="Responsibility"
                                  />
                                </BaseForm.Item>
                              </S.FormItemStyle>
                            </S.FormListStyle>
                            <S.FormListStyle>
                              <S.FormItemStyle className="layoutColumn">
                                <div style={{ marginBottom: '8px' }}>Technology</div>
                                <BaseForm.Item
                                  {...restField}
                                  name={[name, 'technology']}
                                  rules={[{ required: true, message: 'Missing Technology' }]}
                                >
                                  <S.CustomInput
                                    style={{ borderRadius: 4, width: '100%' }}
                                    size="large"
                                    placeholder="Technology"
                                  />
                                </BaseForm.Item>
                              </S.FormItemStyle>
                            </S.FormListStyle>
                            <S.FormListStyle>
                              <S.FormItemStyle className="layoutColumn">
                                <div style={{ marginBottom: '8px' }}>Description</div>
                                <BaseForm.Item
                                  {...restField}
                                  name={[name, 'description']}
                                  rules={[{ required: true, message: 'Missing Description' }]}
                                >
                                  <TextArea rows={4} autoSize={false} placeholder="Description" />
                                </BaseForm.Item>
                              </S.FormItemStyle>
                            </S.FormListStyle>
                          </S.FormListWrapStyle>
                        ))}
                        <BaseForm.Item>
                          <Button
                            type="primary"
                            ghost
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                            style={{ width: '24px', height: '24px' }}
                          />
                        </BaseForm.Item>
                      </>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </>
          )}
        </BaseForm>
      </BaseModal>
    </>
  );
};

export default ModelInsert;
