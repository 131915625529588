import { createSlice } from '@reduxjs/toolkit';

import { State } from './type';
import { getDepartmentByAccount, getDepartmentList } from './actions';

const initialState: State = {
  list: {
    loading: false,
    result: [],
  },
  listbyacc: {
    loading: false,
    result: [],
  },
  item: {
    loading: false,
    result: [],
  },
};

export const slice = createSlice({
  name: 'department',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDepartmentList.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(getDepartmentList.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(getDepartmentList.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });
    builder.addCase(getDepartmentByAccount.pending, (state) => {
      state.listbyacc.loading = true;
      state.listbyacc.result = initialState.listbyacc.result;
      state.listbyacc.error = {};
    });
    builder.addCase(getDepartmentByAccount.fulfilled, (state, action) => {
      state.listbyacc.loading = false;
      state.listbyacc.result = action.payload;
      state.listbyacc.error = {};
    });
    builder.addCase(getDepartmentByAccount.rejected, (state, action) => {
      state.listbyacc.loading = false;
      state.listbyacc.error = action.error;
      state.listbyacc.result = initialState.listbyacc.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  getDepartmentList,
  getDepartmentByAccount,
};

export const { reducer } = slice;
