import { createSlice } from '@reduxjs/toolkit';

import { State } from './type';
import { importData, previewUpload, previewUploadJira, updateCVEmployee, uploadCv, uploadDataJira } from './actions';

const initialState: State = {
  review: {
    loading: false,
    result: [],
  },
  reviewJira: {
    loading: false,
    result: [],
  },
  list: {
    loading: false,
    result: [],
  },
  dataJira: {
    loading: false,
    result: [],
  },
  updateCV: {
    loading: false,
    result: [],
  },
};

export const slice = createSlice({
  name: 'upload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(previewUpload.pending, (state) => {
      state.review.loading = true;
      state.review.result = initialState.review.result;
      state.review.error = {};
    });
    builder.addCase(previewUpload.fulfilled, (state, action) => {
      state.review.loading = false;
      state.review.result = action.payload;
      state.review.error = {};
    });
    builder.addCase(previewUpload.rejected, (state, action) => {
      state.review.loading = false;
      state.review.error = action.error;
      state.review.result = initialState.review.result;
    });

    builder.addCase(importData.pending, (state) => {
      state.list.loading = true;
      state.list.result = initialState.list.result;
      state.list.error = {};
    });
    builder.addCase(importData.fulfilled, (state, action) => {
      state.list.loading = false;
      state.list.result = action.payload;
      state.list.error = {};
    });
    builder.addCase(importData.rejected, (state, action) => {
      state.list.loading = false;
      state.list.error = action.error;
      state.list.result = initialState.list.result;
    });

    builder.addCase(uploadCv.pending, (state) => {
      state.review.loading = true;
      state.review.result = initialState.review.result;
      state.review.error = {};
    });
    builder.addCase(uploadCv.fulfilled, (state, action) => {
      state.review.loading = false;
      state.review.result = action.payload;
      state.review.error = {};
    });
    builder.addCase(uploadCv.rejected, (state, action) => {
      state.review.loading = false;
      state.review.error = action.error;
      state.review.result = initialState.review.result;
    });
    builder.addCase(previewUploadJira.pending, (state) => {
      state.reviewJira.loading = true;
      state.reviewJira.result = initialState.reviewJira.result;
      state.reviewJira.error = {};
    });
    builder.addCase(previewUploadJira.fulfilled, (state, action) => {
      state.reviewJira.loading = false;
      state.reviewJira.result = action.payload;
      state.reviewJira.error = {};
    });
    builder.addCase(previewUploadJira.rejected, (state, action) => {
      state.reviewJira.loading = false;
      state.reviewJira.error = action.error;
      state.reviewJira.result = initialState.reviewJira.result;
    });

    builder.addCase(uploadDataJira.pending, (state) => {
      state.dataJira.loading = true;
      state.dataJira.result = initialState.dataJira.result;
      state.dataJira.error = {};
    });
    builder.addCase(uploadDataJira.fulfilled, (state, action) => {
      state.dataJira.loading = false;
      state.dataJira.result = action.payload;
      state.dataJira.error = {};
    });
    builder.addCase(uploadDataJira.rejected, (state, action) => {
      state.dataJira.loading = false;
      state.dataJira.error = action.error;
      state.dataJira.result = initialState.dataJira.result;
    });
    builder.addCase(updateCVEmployee.pending, (state) => {
      state.updateCV.loading = true;
      state.updateCV.result = initialState.updateCV.result;
      state.updateCV.error = {};
    });
    builder.addCase(updateCVEmployee.fulfilled, (state, action) => {
      state.updateCV.loading = false;
      state.updateCV.result = action.payload;
      state.updateCV.error = {};
    });
    builder.addCase(updateCVEmployee.rejected, (state, action) => {
      state.updateCV.loading = false;
      state.updateCV.error = action.error;
      state.updateCV.result = initialState.updateCV.result;
    });
  },
});

export const actions = {
  ...slice.actions,
  importData,
  previewUpload,
  uploadCv,
  previewUploadJira,
  uploadDataJira,
  updateCVEmployee,
};

export const { reducer } = slice;
